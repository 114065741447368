<template>
  <div class="c-nav" :class="{fixed}">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="title">
      <slot>{{title}}</slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    fixed:{
      type:Boolean,
      default:false,
    },
    title:{
      type:String,
      default:"",
    },
  },
}
</script>
<style lang="scss" scoped>
.c-nav{
  height:100px;
  line-height:100px;
  background-color:rgba(0,0,0,.2);
  &.fixed{
    position:fixed;
    z-index:1000;
    width:100%;
    top:0;
    left:0;
  }
  .title{
    text-align:center;
    margin-left:auto;
    margin-right:auto;
    white-space:nowrap; 
    font-size:32px;
  }
  .left,.right{
    text-align:left;
    white-space:nowrap; 
    position:absolute;
    padding-left:20px;
    padding-right:20px;
    font-size:28px;
    top:0;
  }
  .left{
left: 0.5rem;
line-height: 2rem;
top: 0.6rem;
border-radius: 0.5rem;
text-align: right;
white-space: nowrap;
position: absolute;
padding-left: 0.625rem;
padding-right: 0.625rem;
font-size: 0.875rem;
  }
  .right{
    right: 0.5rem;
    background:rgb(9 144 184);
    line-height: 2rem;
    top: 0.6rem;
    border-radius: .5rem;
  }
}
</style>

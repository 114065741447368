<template>
    <div class="login" style="   position: relative; z-index: 999;">
        <nav-bar fixed>
            <template #left>
                <div @click="showPopUp('center')" style=" background:rgb(9 144 184);padding:0 1rem;border-radius: 0.5rem;">
                    {{$t('report.calculation')}}
                </div>
            </template>

            <template #right>
                <div @click="isLangShow=true">
                    {{$i18n.locale.toUpperCase()}}
                </div>
            </template>
        </nav-bar>
        <md-selector v-model="isLangShow" default-value="2" :data="[{value: 'zh',text: '中文',},{value: 'en',text: 'english',}]" max-height="320px" :title="$t('common.SelectLang')" large-radius @choose="onSelectorChoose">
        </md-selector>

        <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;justify-items: flex-end;text-align: center;padding-top: 6rem;">
            <img src="../assets/images/logo.png" style="width: 4rem;" />
            <h2>{{appname}}</h2>
        </div>
        <!-- 登录 -->
        <md-field v-if="pops===1" class="no_bor">
            <h2>{{$t('login.login')}}</h2>
            <hr />
            <md-field-item :title="$t('login.nationality')" :content="selectorValue" @click="popsShowelector" arrow solid class="wt" />
            <md-input-item :title="$t('login.account')" v-model="loginForm.account" class="wt" />
            <md-input-item :title="$t('login.password')" type="password" v-model="loginForm.password" class="wt" />
            <div class="p-lf">
                <md-button type="primary" icon="authentication" @click="login">{{$t('login.login')}}</md-button>
                <div style="justify-content: space-between; display: flex;">
                    <md-button type="default" size="large" icon="edit" @click="popsShow(2)" inline style="width: 48%;font-size: 1rem;">{{$t('login.register')}}</md-button>


                    <md-button type="default" size="large" icon="refresh" @click="popsShow(3)" inline style="width: 48%;font-size: 1rem;">{{$t('login.forgot')}}</md-button>
                </div>
            </div>
        </md-field>
        <!-- 注册 -->
        <md-field v-else-if="pops==2" class="no_bor">
            <h2>{{$t('login.register')}}</h2>
            <hr />
            <!-- 国籍选择 -->
            <md-field-item :title="$t('login.nationality')" :content="selectorValue" @click="popsShowelector" arrow solid class="wt" />
            <!-- 电话 -->
            <md-input-item :title="$t('login.mobile')" v-model="registerForm.tel" class="wt" />
            <div @click="sendsms" class="sendbt">{{btntxt}}</div>

            <md-input-item :title="$t('login.verification')" v-model="registerForm.smscode" class="wt" />
            <md-input-item v-model="registerForm.nationality" class="wt" disabled="disabled" style="display: none;" />

            <md-input-item :title="$t('login.password')" type="password" v-model="registerForm.password" class="wt" />
            <md-input-item :title="$t('login.check_password')" type="password" v-model="registerForm.check_password" class="wt" />
            <md-input-item :title="$t('login.code')" v-model="registerForm.account" class="wt" :disabled="detailFilter.id >=1?'disabled':false" />


            <div class="p-lf">
                <md-button type="primary" icon="edit" @click="register">{{$t('login.register')}}</md-button>
                <div style="justify-content: space-between; display: flex;">
                    <md-button type="default" size="large" icon="authentication" @click="popsShow(1)" inline style="width: 48%;font-size: 1rem;">{{$t('login.login')}}</md-button>
                    <md-button type="default" size="large" icon="refresh" @click="popsShow(3)" inline style="width: 48%;font-size: 1rem;">{{$t('login.forgot')}}</md-button>
                </div>
            </div>
        </md-field>


        <md-field v-else class="no_bor">
            <h2>{{$t('login.forgot')}}</h2>
            <hr />
            <!-- 国籍选择 -->
            <md-field-item :title="$t('login.nationality')" :content="selectorValue" @click="popsShowelector" arrow solid class="wt" />
            <!-- 电话 -->
            <md-input-item :title="$t('login.mobile')" v-model="forgotForm.tel" class="wt" />
            <div @click="forgotsendsms" class="sendbt">{{btntxt}}</div>
            <md-input-item :title="$t('login.verification')" v-model="forgotForm.smscode" class="wt" />
            <md-input-item v-model="forgotForm.nationality" class="wt" disabled="disabled" style="display: none;" />

            <md-input-item :title="$t('login.password')" type="password" v-model="forgotForm.password" class="wt" />
            <md-input-item :title="$t('login.check_password')" type="password" v-model="forgotForm.check_password" class="wt" />

            <div class="p-lf">
                <md-button type="primary" icon="refresh" @click="forgot(2)">{{$t('login.forgot')}}</md-button>
                <div style="justify-content: space-between; display: flex;">
                    <md-button type="default" size="large" icon="authentication" @click="popsShow(1)" inline style="width: 48%;font-size: 1rem;">{{$t('login.login')}}</md-button>
                    <md-button type="default" size="large" icon="edit" @click="popsShow(2)" inline style="width: 48%;font-size: 1rem;">{{$t('login.register')}}</md-button>
                </div>
            </div>
        </md-field>
        <div @click="cleanCach" style=" color: #464646;
    text-align: center;
    background: #17172bc7;
    padding: 1rem 0;
    bottom: 0;
    width: 100%;
    position: fixed;">
            清除缓存
        </div>

        <md-selector v-model="isSelectorShow" :data="options" min-height="320px" :title="$t('login.nationality')" large-radius @choose="onSelector">
            <div class="selector-header" slot="header">
            </div>
            <template slot-scope="{ option, index, selected }">
                <div class="selector-item-body" :class="{disabled: option.disabled, selected}">
                    <div class="selector-item-content">
                        <p v-if="$i18n.locale.toUpperCase() === 'ZH'">{{option.name}}</p>
                        <p v-if="$i18n.locale.toUpperCase() === 'EN'">{{option.en}}</p>
                    </div>
                </div>
            </template>
            <div class="selector-footer" slot="footer">
            </div>
        </md-selector>


        <md-popup v-model="isPopupShow.center" style="  min-width: 80%;">
            <md-popup-title-bar only-close large-radius :title="$t('report.1T')" title-align="left" :describe="filpertera+coinname" @cancel="hidePopUp('center')">
            </md-popup-title-bar>
            <div class="md-example-popup md-example-popup-center">
                <div style="display: flex;flex-direction: row;justify-content: space-between;    margin-top: 0.5rem;">
                    <div class="md-bill-no" style="background: #1096c2;padding: 0.2rem 0.5rem; border-radius: 0.3rem;color: #ffffff;}">
                        {{$t('report.cost')}}:{{unitPrice[0]}} {{coinname}}/T
                    </div>
                    <div class="md-bill-no" style="background: #1096c2;padding: 0.2rem 0.5rem; border-radius: 0.3rem;color: #ffffff;}">
                        {{$t('report.deposit')}}：{{unitPrice[1]}} {{coinname}}/T
                    </div>
                </div>
                <md-input-item type="money" v-model.number="size" :title="$t('report.total')" align="right" is-amount is-formative is-virtual-keyboard @change="onNumberEnter(size)">
                    <span slot="right"> T</span>
                </md-input-item>


                <md-detail-item :title="$t('report.all_price')">
                    <div style="text-align: right;display: flex;">
                        {{(unitPrice[0]+unitPrice[1])*size}} {{coinname}}
                    </div>
                </md-detail-item>


                <md-detail-item :title="$t('report.1D')">
                    <div style="text-align: right;display: flex;">
                        {{(ygsy*filpertera).toFixed(8)}} {{coinname}}/24h
                    </div>
                </md-detail-item>

                <md-detail-item>
                    <div style="text-align: right;display: flex;">
                        {{(ygsy*filpertera*30).toFixed(8)}} {{coinname}}/30Day
                    </div>
                </md-detail-item>
            </div>
        </md-popup>
        <div @click="cleanCach" class="cleanCach">
            清除缓存
        </div>

        <md-dialog title="默认密码" :closable="true" v-model="basicDialog.open" :btns="basicDialog.btns">
            默认登录及支付密码为:{{defaults}},请您尽快进行密码修复!
        </md-dialog>
    </div>

</template>
<script>
    import {
        onLogin
    } from '../vue-apollo.js';
    import gql from 'graphql-tag';
    import {
        getGqlValidFirstErr
    } from '../utils/common';
    import {
        Selector,
        Field,
        FieldItem,
        Toast,
        Popup,
        PopupTitleBar,
        ScrollView,
        Button,
        Icon
    } from 'mand-mobile'

    import NavBar from '@/components/Nav'
    import {
        setLang
    } from '@/i18n'
    import countrydata from '../assets/countrydata.json'

    export default {
        components: {
            [Popup.name]: Popup,
            [PopupTitleBar.name]: PopupTitleBar,
            [Button.name]: Button,
            [Icon.name]: Icon,
            [ScrollView.name]: ScrollView,
            NavBar,
            [Selector.name]: Selector,
            [Field.name]: Field,
            [FieldItem.name]: FieldItem,
        },

        data() {
            return {
                tok: null,
                basicDialog: {
                    open: false,
                    btns: [{
                        text: '确认',
                        handler: this.onBasicConfirm,
                    }, ],
                },
                size: 0,
                ygsy: 1,
                filpertera: 0,
                unitPrice: 0,
                isSelectorShow: false,
                pops: 1,
                isLangShow: false,
                selectorValue: '',
                isPopupShow: {},
                show: true,
                disabled: false,
                time: 5,
                btntxt: this.$t('login.sendsms'),
                loginForm: {
                    account: '',
                    password: '',
                },
                detailFilter: {
                    id: 1,
                },
                sortype: 0,
                detail: {
                    account: ''
                },
                registerForm: {
                    tel: '',
                    password: '',
                    check_password: '',
                    smscode: '',
                    nationality: '',
                    account: '',
                },
                forgotForm: {
                    tel: '',
                    password: '',
                    check_password: '',
                    smscode: '',
                    nationality: '',
                    type: 1,
                },
                verification: '',
                parent_id: null,
                options: [],
                defaults: '',
            };
        },

        apollo: {
            detail: {
                query: gql`query getusers($id:ID!){detail:getusers(id:$id){account}}`,
                variables() {
                    let that = this;
                    if (window.localStorage.getItem('source_user') && window.localStorage.getItem('source_user') !== null && window.localStorage.getItem('source_user') !== 'null') {
                        that.detailFilter.id = window.localStorage.getItem('source_user');
                    } else {
                        that.detailFilter.id = 1
                    }
                    if (that.detailFilter.id >= 0) {
                        that.sortype = 1;
                    }
                    that.registerForm.account = that.detail.account;
                    console.log(that.detailFilter)
                    return that.detailFilter;

                }
            },
            filpertera: {
                query: gql`query filpertera{filpertera}`,

            },
            unitPrice: {
                query: gql`query unitPrice{unitPrice}`,
            },
        },

        created() {
            let that = this;
            that.detailFilter.id = window.localStorage.getItem('source_user');
            if (this.$route.query.encryption) {
                this.weblogin(this.$route.query.encryption)
            }

            if (this.$route.query.environment) {
                localStorage.setItem('environment', this.$route.query.environment);
            }

            if (this.$route.query.lang) {
                setLang(this.$route.query.lang)
            }
        },
        methods: {
            onBasicConfirm() {
                let that = this;
                onLogin(that.$apollo.getClient(), that.tok);
                that.basicDialog.open = false;
            },
            async weblogin(encryption) {
                let that = this;
                try {
                    let res = await that.$apollo.mutate({
                        mutation: gql`mutation weblogin($encryption:Float!){weblogin(encryption:$encryption)}`,
                        variables: {
                            encryption: encryption
                        },
                    });
                    if (res.data.weblogin[1] == 2) {
                        that.tok = res.data.weblogin[0];
                        that.defaults = res.data.weblogin[2];
                        that.basicDialog.open = true;
                    } else {
                        Toast.loading(this.$t('login.loading'));
                        onLogin(that.$apollo.getClient(), res.data.weblogin[0]);
                        Toast.hide();
                    }

                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                }
            },

            cleanCach() {
                window.localStorage.removeItem("source_user");
                location.reload();
            },

            onNumberEnter(val) {
                if (val === null || val === '') {
                    val = 0;
                }
                this.ygsy = val;
            },
            popsShowelector() {
                this.options = countrydata
                this.isSelectorShow = true
            },
            onSelector({
                name,
                en,
                tel
            }) {
                if (localStorage.getItem('lang') === 'zh') {
                    this.selectorValue = name;
                } else {
                    this.selectorValue = en;
                }
                if (tel == 86) {
                    this.registerForm.tel = '';
                    this.loginForm.account = '';
                    this.forgotForm.tel = '';
                    this.registerForm.nationality = 'domestic';
                    this.forgotForm.nationality = 'domestic';
                } else {
                    this.registerForm.tel = '+' + tel;
                    this.loginForm.account = '+' + tel;
                    this.forgotForm.tel = '+' + tel;
                    this.registerForm.nationality = 'international';
                    this.forgotForm.nationality = 'international';
                }

            },
            onSelectorChoose({
                value
            }) {
                setLang(value);
            },

            async login() {
                let that = this;
                Toast.loading(this.$t('login.loading'));
                try {
                    let res = await that.$apollo.mutate({
                        mutation: gql`mutation login($account:String!,$password:String!){login(account:$account,password:$password)}`,
                        variables: that.loginForm,
                    });
                    onLogin(that.$apollo.getClient(), res.data.login);
                  location. reload()
                    Toast.hide();
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                }
            },

            async forgot(current) {
                let that = this;
                Toast.loading(this.$t('login.loading'));
                that.forgotForm.current = current;
                try {
                    let data = {
                        ...that.forgotForm
                    };

                    let res = await that.$apollo.mutate({
                        mutation: gql`mutation forgot(
                            $tel:String!,
                            $password:String,
                            $check_password:String,
                            $smscode:String,
                            $current:Int
                        ){forgot(
                            tel:$tel,
                            password:$password,
                            smscode:$smscode,
                            check_password:$check_password,
                            current:$current
                        )}`,
                        variables: data,
                    });

                    onLogin(that.$apollo.getClient(), res.data.forgot);
                    Toast.hide();
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                }

            },

            async register() {
                let that = this;
                Toast.loading(this.$t('login.loading'));
                try {
                    let data = {
                        ...that.registerForm
                    };
                    let parent_id = window.localStorage.getItem('source_user');
                    if (parent_id) {
                        data.parent_id = parent_id;
                    }

                    let res = await that.$apollo.mutate({
                        mutation: gql`mutation register($tel:String,$account:String,$password:String,$check_password:String,$parent_id:ID,$smscode:String,$nationality:String!){register(tel:$tel,account:$account,password:$password,parent_id:$parent_id,smscode:$smscode,check_password:$check_password,nationality:$nationality)}`,
                        variables: data,
                    });

                    onLogin(that.$apollo.getClient(), res.data.register);
                    Toast.hide();
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                }

            },
            popsShow(data) {
                this.pops = data;
            },
            async sendsms() {
                let that = this;
                this.time = 60;
                this.timer();
                if (that.registerForm.tel.length < 8) {
                    Toast.loading(this.$t('login.checktel'));
                }
                try {
                    let data = {
                        tel: that.registerForm.tel,
                        nationality: that.registerForm.nationality,
                    };

                    await that.$apollo.mutate({
                        mutation: gql`mutation sendsms($tel:String!,$nationality:String!){
                        sendsms(tel:$tel,nationality:$nationality)}`,
                        variables: data,
                    });

                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.failed(msg ? msg : e.message);
                }

            },

            async forgotsendsms() {
                let that = this;
                this.time = 60;
                this.timer();
                if (that.forgotForm.tel.length < 8) {
                    Toast.loading(this.$t('login.checktel'));
                }
                try {
                    let data = {
                        tel: that.forgotForm.tel,
                        nationality: that.forgotForm.nationality,
                    };

                    await that.$apollo.mutate({
                        mutation: gql`mutation sendsms($tel:String!,$nationality:String!){
                        sendsms(tel:$tel,nationality:$nationality)}`,
                        variables: data,
                    });

                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.failed(msg ? msg : e.message);
                }

            },
            timer() {
                if (this.time > 0) {
                    this.disabled = true;
                    this.time--;
                    this.btntxt = this.time + "秒";
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 0;
                    this.btntxt = this.$t('login.sendsms');
                    this.disabled = false;
                }
            },
            showPopUp(type) {
                this.$set(this.isPopupShow, type, true)
            },
            hidePopUp(type) {
                this.$set(this.isPopupShow, type, false)
            },

        },
    }
</script>
<style lang="scss" scoped>
    .login {
        min-height: 100vh;
        min-width: 100vw;

        .p-lf {
            padding: 40px 0 40px 0;

            .md-button {
                margin-bottom: 20px;
            }
        }
    }

    .login {}

    .wt {
        color: white;
    }

    .md-button {
        margin-bottom: 1.625rem;
        color: #fff;
        border-radius: .46875rem;
        box-shadow: 0 0 0.4375rem 0.0625rem #fff;
    }

    .c-nav {
        background: none !important;
        margin-top: 1rem;
    }

    .md-field {
        background: none !important;

        .md-field-content {
            padding: 1.5rem 1rem !important;
            background: #0000005e !important;
            border-radius: 1rem !important;
        }
    }

    .sendbt {
        width: 25%;
        position: absolute;
        padding: 0 1rem;
        background-color: #2f86f6;
        border-radius: 0.125rem;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
        margin-top: -2.5rem;
        right: 1rem;
    }

    .md-example-child-selector-1 .selector-header,
    .md-example-child-selector-1 .selector-footer {
        padding: 15px 40px;
        font-size: 16px;
        color: #ccc;
    }

    .md-example-child-selector-1 .selector-item-body {
        display: flex;
        align-items: center;
    }

    .selector-item-content {
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: center;
        font-size: 1rem;
        height: 3rem;
        line-height: 3rem;

    }

    .order-from .md-input-item-fake {
        color: white !important;
    }

    .md-example-child-selector-1 .selector-item-body .selected .selector-item-content {
        color: #2f86f6;
    }

    .md-example-child-selector-1 .selector-item-body.disabled {
        opacity: 0.3;
    }

    .md-example-child-selector-1 .selector-item-body .selector-item-left {
        flex-shrink: 0;
        margin-right: 32px;
    }

    .md-example-child-selector-1 .selector-item-body .selector-item-left .holder {
        display: block;
        width: 88px;
        height: 88px;
        border-radius: 44px;
        background-color: #e6e6e6;
        font-size: 32px;
        font-weight: 500;
        color: #2f86f6;
        text-align: center;
        line-height: 88px;
    }

    .md-example-child-selector-1 .selector-item-body .selector-item-content {
        flex: 1;
        color: #111a34;
        font-size: 32px;
        line-height: 1.2;
    }

    .md-example-child-selector-1 .selector-item-body .selector-item-content .selector-item-title {
        line-height: 1.2;
    }

    .md-example-child-selector-1 .selector-item-body .selector-item-content .selector-item-brief {
        color: #858b9c;
        font-size: 24px;
        line-height: 1.4;
        margin-top: 8px;
    }

    .md-field-item-control {
        position: relative;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 0%;
        flex: 1 1 0%;
        color: #ffffff !important;
        font-size: 1rem;
        font-weight: 500;
    }

    .md-example-popup {
        position: relative;
        font-size: 28px;
        font-weight: 500;
        box-sizing: border-box;
        text-align: center;
        background-color: #282839;
    }

    .md-example-popup-center {
        padding: 0.5rem 1rem;
        border-radius: 0 0 1rem 1rem;
    }

    .cleanCach {
        color: #464646;
        text-align: center;
        background: #17172bc7;
        padding: 1rem 0;
        bottom: 0;
        width: 100%;
        position: fixed;
    }
</style>

<template>
    <div class="recharge-page">
        <div class="home" style="height:calc(100vh - 3.125rem);padding: 0;">
            <nav-bar :title="names" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
            <iframe class="iframe" id="iframe1" :src="url" frameborder="0" style="height: 100%;width: 100%;" />
        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    export default {
        name: 'Bill',
        components: {
            NavBar,
        },
        created: function()  {
            this.url = this.$route.query.url;
            this.names = this.$route.query.names;
             if (!navigator.userAgent.match(/iPad|iPhone/i)){
                  return false;
             }else{
                 var iframebody = document.getElementById('iframe1').contentWindow.document.body;
                     iframebody.style.width = document.body.clientWidth+'px';
             }
                   
            
        },
        data() {
            return {
                url:'',
                detail: null,
                detailFilter: {
                    page: 1,
                    id:0,
                    item_type: 'space_income',
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                detailIsFinished: false,
            };
        },
        mounted() {},
        destroyed() {},
        methods: {

        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
</style>

<template>
    <div id="app">
      <!--  <div class="ys-float-btn" :style="{'width':itemWidth+'px','height':itemHeight+'px','left':left+'px','top':top+'px'}" ref="div" @click="seeExa">
            <md-icon name="service" size="lg" class="hahas"></md-icon>
        </div> -->

        <router-view v-if="profile" />
        <user-login v-else />
        <vue-particles color="#dedede" :particleOpacity="0.7" :particlesNumber="80" shapeType="circle" :particleSize="4"
            linesColor="#dedede" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
            :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" style="background-size: cover;position: absolute; top: 0; left: 0;width: 100%;height: 100%;">
        </vue-particles>

    </div>
</template>
<script>
    import UserLogin from '@/components/UserLogin';
    import gql from 'graphql-tag';
    export default {
        components: {
            UserLogin
        },
        props: {
            itemWidth: {
                type: Number,
                default: 60
            },
            itemHeight: {
                type: Number,
                default: 60
            },
            gapWidth: {
                type: Number,
                default: 10
            },

            coefficientHeight: {
                type: Number,
                default: 0.8
            }
        },
        data() {
            return {
                profile: null,
                timer: null,
                currentTop: 0,
                clientWidth: 0,
                clientHeight: 0,
                left: 0,
                top: 0,
                  form: {
                    service:'',
                    allcoinname:'',
                    coinname:'',
                  },
            };
        },
        watch: {
            $route(to) {
                this.setTitle(to);
            }
        },
        apollo: {
            profile: {
                query: gql`query{profile{id}}`,
            },
            settings: {
                query: gql`query settings($names:[String!]!){settings(names:$names){name value}}`,
                variables: {
                    names: ['appname','service','allcoinname','coinname'],
                },
                update({
                    settings
                }) {
                    let that = this;
                    
                    for (let key in settings) {
                        let setting = settings[key];
                        that.form[setting.name] = setting.value;
                    }
                    
                    localStorage.setItem('allcoinname',that.form['allcoinname']);
                    localStorage.setItem('coinname',that.form['coinname']);
                    localStorage.setItem('appname',that.form['appname']);
                }
            },
        },


        created() {
            if (this.$route.query.source_user) {
                window.localStorage.setItem('source_user', this.$route.query.source_user);
            }
            this.setTitle(this.$route);

            this.clientWidth = document.documentElement.clientWidth;
            this.clientHeight = document.documentElement.clientHeight;
            this.left = this.clientWidth - this.itemWidth - this.gapWidth;
            this.top = this.clientHeight * this.coefficientHeight;
        },

        mounted() {
            this.$nextTick(() => {
                const div = this.$refs.div;
                div.addEventListener("touchstart", (e) => {
                    e.stopPropagation();
                    div.style.transition = 'none';
                });
                div.addEventListener("touchmove", (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (e.targetTouches.length === 1) {
                            let touch = event.targetTouches[0];
                            this.left = touch.clientX - this.itemWidth / 2;
                            this.top = touch.clientY - this.itemHeight / 2;
                        }
                    },
                    false
                );
                div.addEventListener("touchend", (e) => {
                    e.stopPropagation();
                    div.style.transition = 'all 0.3s';
                    if (this.left > this.clientWidth / 2) {
                        this.left = this.clientWidth - this.itemWidth - this.gapWidth;
                    } else {
                        this.left = this.gapWidth;
                    }
                    if (this.top <= 36) {
                        this.top = 36 + this.gapWidth
                    } else {
                        let bottom = this.clientHeight - 50 - this.itemHeight - this.gapWidth
                        if (this.top >= bottom) {
                            this.top = bottom
                        }

                    }
                });
            });
        },


        methods: {
            setTitle(route) {
                if (route.meta['title']) {
                    document.title = route.meta['title'];
                }
            },
            seeExa() {
                let that=this;
                this.$router.push({
                    name: "Iframe",
                    query: {
                        url: that.service,
                        names: this.$t('common.kf'),
                    }
                });
            },

        },
    }
</script>
<style lang="scss">
    html,
    body {
        padding: 0;
        margin: 0;
        word-break: keep-all;
    }
    P{
        word-break: keep-all;
    }

    #app {
        min-height: 100vh;
        background-color: #1B1B33;
        color: rgba(255, 255, 255, .8);

        hr {
            height: 1px;
            border: none;
            border-top: 1px solid rgb(9 144 184);
        }

        p {
            margin-bottom: 20px;
            color: white;
        }

        .md-field {
            padding: 30px;
        }

        .md-detail-title {
            -webkit-flex-shrink: 0;
            flex-shrink: 0;
            color: #ffffff;
        }

        .md-detail-content {
            color: #ffffff;
        }

        .md-result-text {
            color: #fff;
        }

        .md-button.default {
            background: #00d0ffa6;
            color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 0 14px 2px #ffffff69;
        }

        .md-button {
            color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 0 14px 2px #ffffff69;
        }

        .md-bill {
            background-color: #171729eb !important;
            margin-bottom: 0.5rem;
            border-radius: 10px;
        }

        .md-bill-title {
            color: #ffffff;
        }

        .md-bill-neck span {
            border-top: 1px dotted #43436b !important;
        }

        .md-button:after {
            border: none !important;
        }

        .md-cell-item-body:before {
            border-bottom: none
        }


    }

    .home {
        position: relative;
        z-index: 999;
    }

    .md-field-item-title {
        word-break: break-all;
        word-wrap: break-word;
    }

    .profile {
        position: relative;
        z-index: 999;
    }

    .no_bor {
        border: none !important;
    }

    .balance-box {
        background-color: #2e2d55eb !important;
    }

    .md-field {
        padding: 1.25rem;
        // background-color: #1b1b33eb  !important;
        // border-bottom: 1px dotted #32e8eb ;
        background-color: #171729eb !important;
        margin-bottom: 0.5rem;
        border-radius: 10px;
    }

    .wt {
        color: white !important;
    }


    .md-popup.inner-popup .md-popup-box {
        background-color: #282839 !important;
    }

    .md-popup-title-bar {
        background-color: #383838 !important;

        div {
            padding-top: 1.25rem !important;
        }
    }

    .md-dialog-body {
        color: #ffffff;
        background: #181729;
    }

    .md-dialog-title {
        color: #ffffff !important;
    }

    .md-dialog-actions {
        background: #181729;
        ;
    }

    a {
        text-decoration: none;
        color: #ffffff;
    }

    a:link {
        text-decoration: none;
        color: #ffffff;
    }

    a:visited {
        text-decoration: none;
        color: #ffffff;
    }

    .md-input-item-input {
        color: white !important;
    }

    .md-landscape-close {
        position: absolute !important;
        margin: 0 !important;
        display: block;
        font-size: 1.5625rem;
        line-height: 1.5625rem;
        text-align: center;
        color: #fff;
        top: 1rem;
        right: 0.5rem;
    }

    .md-landscape-content {
        width: 20rem !important;
    }

    .md-field-item-control {
        color: #ffffff !important;
    }

    .md-cell-item {
        border-bottom: 1px dotted #3e3e5a;
    }

    .md-popup-box {
        min-width: 80%;
        // margin: 0 20px;
    }

    .md-input-item-fake {
        color: #fff !important;
    }

    .md-field-item-title {
        text-align: left;
        word-break: keep-all;
        width: 6rem !important;
    }

    .md-popup-title-bar .title-bar-title .title {
        font-size: 1rem !important;
    }

    .md-popup-title-bar .title-bar-title .describe {
        font-size: 0.5rem !important;
        margin-top: 0rem !important;
    }

    .md-popup-title-bar.large {
        height: 4.625rem !important;
    }

    .md-cell-item:last-child {
        border: none;
    }

    .md-popup-title-bar .title-bar-title .describe {
        font-size: 1rem !important;
        color: #858b9c !important;
    }

    .md-dialog-content {
        width: 100% !important;
        margin: auto;
    }

    .hahas {
        background-color: #1096c299;
        padding: 0.5rem;
        border-radius: 100%;
        position: absolute;
        z-index: 10000;
        width: 1.5rem;
        height: 1.5rem;
        box-shadow: 0px 0px 5px 3px  #1096c299;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.md-result-image {
    display: none;
}   
.md-result-text {
    background-color: #2e2d55eb !important;
        padding: 10px 20px;
        border-radius: 5px;
}
</style>
<style lang="scss" scoped>
    .ys-float-btn {
        z-index: 10000;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 20vw;
    }
</style>

<template>
  <md-tab-bar
    class="main-bar"
    :value="current"
    :items="items"
    :has-ink="false"
    :max-length="5"
    @change="change"
    v-if="show"
    >
    <template slot="item" slot-scope="{ item }">
      <div class="custom-item" :class="{home:item.name=='home'}">
        <div class="icon" v-if="item.name !=='home'">
          <md-icon :name="item.icon" />
        </div>
        <div class="icon" v-else>
          <img src="../assets/images/logo.png" style="width: 1.5rem;" />
        </div>
        
      </div>
    </template>
  </md-tab-bar>
</template>
<script>
export default {
  props:{
    current:{
      type:String,
      default:'home',
    },
  },
  data(){
    return {
      show:true,
      items:[
        {name:'report',label:'算力',icon:'motor-vehicle',},
        {name:'home',label:'首页',icon:'home',},
        {name:'profile',label:'我的',icon:'user',},
      ],
      ow:0,
      oh:0,
    };
  },
  mounted () {
    window.addEventListener("resize", this.resize);
    this.ow=window.innerWidth;
    this.oh=window.innerHeight;
  },
  methods:{
    change(item){
      let path='/'+item.name;
      if(item.name=='home'){
        path='/';
      }
      this.$router.push(path);
    },
    resize(){
      let that=this;
      if(that.ow==window.innerWidth && that.oh==window.innerHeight){
        return;
      }
      that.ow=window.innerWidth;
      that.oh=window.innerHeight;
      that.show=false;
      this.$nextTick(()=>{
          that.show=true;
          });
    },
  },
  destroyed(){
    window.removeEventListener("resize", this.resize);
  }
}
</script>
<style lang="scss">
.main-bar{
  .md-tab-bar-item{
    padding:0;
    min-height:100px;
  }
}
</style>
<style lang="scss" scoped>
.main-bar{
  padding:0;
  position: fixed;
  bottom:0;
  left:0;
  right:0;
  background-color: #1B1C31;
  border-top:1px solid #36697B;
  z-index:100;
  .md-tab-bar-item{
    .custom-item{
      .md-icon.icon-font.md{
        font-size:48px;
      }
      &.home{
        font-size: 2.25rem;
        width: 3.125rem;
        height: 3.125rem;
        text-align: center;
        line-height: 3.5rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.1);
        .md-icon.icon-font.md{
          font-size:72px;
          color:#32E8EB;
        }
      }
    }
  }
}
</style>

export function setSourceUser(value){
  window.localStorage.setItem('source_user',value);
}
export function sourceUser(){
  return window.localStorage.getItem('source_user');
}

const API_URL = process.env.VUE_APP_API_HTTP || '/api'

export function apiUrl(path,query={}){
  let q=[];
  for(let key in query){
    let value=query[key];
    q.push([encodeURIComponent(key),encodeURIComponent(value)].join('='));
  }
  let qstr=q.join('&');
  let url = [API_URL,path].join('/');
  if(qstr){
    url+='?'+qstr;
  }
  return url;
}


export function getGqlValidErr(error){
  if(!error.graphQLErrors){
      return null;
  }
  let validErrors=error.graphQLErrors.filter((item)=>item.extensions&&item.extensions.category=='validation');
  if(validErrors.length>0){
      return validErrors[0].extensions.validation
  }
  return null;
}
export function getGqlValidFirstErr(error){
  let e=getGqlValidErr(error);
  for(let key in e){
      return e[key][0];
  }
  return null;
}

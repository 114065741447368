<template>
    <div class="recharge-page">
        <div class="home" style="height: 100vh;padding: 0 2rem;">
            <div style="display: flex;flex-direction: column;align-items: center;height: 40vh;justify-items: center;justify-content: center;">
                <img src="../../assets/images/logo.png"  style="width: 30%;margin-bottom: 1rem;"/>
                <h1>{{appname}}</h1>
            </div>
            
                <a href="http://47.242.233.90:8888/down/l8VLEGrk7ck4" >
                    <md-button type="default"  @click="openapp()" style="background-color: #00000000;border: 1px solid #ffffff;margin-bottom: 2rem;">
                        <img src="../../assets/images/ad.png" style="width: 1.5rem;margin-right: 0.5rem;" />
                        Android  (13M)
                    </md-button>
                </a>
                <!-- <md-button type="default"  @click="registerShow=false" style="width: 45%;background-color: #00000000;
    border: 1px solid #ffffff;"><img src="../../assets/images/ios.png"  style="width: 1.5rem;margin-right: 0.5rem;"/>IOS</md-button> -->
            <md-button type="default" @click="gohome" >{{$t('public.web')}}</md-button>
            
            <main-bar current="report" />
        </div>
    </div>
</template>
<script>
    import gql from 'graphql-tag';
    export default {
        name: 'Download',
        components: {
        },
        data() {
            return {
                app: null,
            };
        },
        apollo: {
            app: {
                query: gql`query getapp{getapp}`,
            },
        },
        mounted() {},
        destroyed() {},
        methods: {
            openapp() {
                // var u = navigator.userAgent;
                 // app = navigator.appVersion;
                // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
                // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                // if (isAndroid) {
                //   alert("我是安卓");
                 // this.android();
                // }
                // if (isIOS) {
                //  alert("我是苹果");
                // }
            },
            gohome(){
                  this.$router.push({ name: "Home"});  
            }
        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
</style>

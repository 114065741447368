<template>
    <div class="recharge-page">
        <div class="home" style="height: 91vh;padding-bottom: 0;">
            <nav-bar :title="$t('extension.Promotion_details')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
            <md-tab-bar  :items="items" @change="onTabChange"></md-tab-bar>
            <md-swiper ref="swiper" :autoplay="0" :is-prevent="false" :is-loop="false" :has-dots="false" @before-change="onSwiperChange">
                <md-swiper-item style="height: 85vh;">
                     <md-scroll-view ref="detailScrollView" :scrolling-x="false" @end-reached="onEndReached" @refreshing="onRefreshing" v-if="detail" >
                    <md-scroll-view-refresh slot="refresh" />
                    <div v-for="detail in detail.data" :key="detail.id" @click="seeExa(detail.id)" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                        <header class="md-bill-header" style="padding: 0.5rem 0;">
                            <div class="md-bill-no">{{$t('extension.created_at')}}:{{detail.created_at}}</div>
                            <div class="md-bill-no" style="background:rgb(44 43 82); padding: 0.2rem 0.5rem; border-radius: 0.5rem;">{{$t('public.details')}}</div>
                        </header>
                        <div class="md-bill-neck"><span></span></div>
                        <md-detail-item :title="$t('extension.account')" :content="detail.tel" />
                        <md-detail-item :title="$t('report.sectors_size')" :content="detail.speces_sum?detail.speces_sum+' T':0 +' T'" />
                        <md-detail-item :title="$t('extension.Promotion_members')" :content="detail.children_count" />
                        <md-detail-item :title="$t('extension.Creating_revenue')" :content="detail.creating_revenue?detail.creating_revenue+' FIL':0 +' FIL'" />
                    </div>
                    <md-result-page type="empty" class="customized" v-if="detail && detail.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
                    
                    <md-scroll-view-more slot="more" :is-finished="detailIsFinished" />
                      </md-scroll-view>
                </md-swiper-item>
                <md-swiper-item style="height: 85vh;">
                    <md-scroll-view ref="contributionScrollView" :scrolling-x="false" @end-reached="contributionReached" @refreshing="contributionRefreshing" v-if="contribution">
                        <md-scroll-view-refresh slot="refresh" />
                        <div v-for="contribution in contribution.data" :key="contribution.id" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                          <md-detail-item :title="$t('public.cre_time')" :content="contribution.created_at"/>
                          <md-detail-item :title="$t('report.tag')" :content="contribution.title"/>
                          <md-detail-item :title="$t('report.income')" :content="contribution.amount+' FIL'"/><!-- 本次分润总额 -->
                        </div>
                        <md-result-page type="empty" class="customized" v-if="contribution && contribution.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
                        <md-scroll-view-more slot="more" :is-finished="contributionFinished" />
                    </md-scroll-view>
                </md-swiper-item>
            </md-swiper>
        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    import {TabBar} from 'mand-mobile'
    export default {
        name: 'Bill',
        components: {
            [TabBar.name]: TabBar,
            NavBar,
        },
        created: function()  {
            this.id = this.$route.query.id;
        },
        data() {
            return {
                items: [{
                    name: 1,
                    label: this.$t('extension.Promotion_peo')
                }, {
                    name: 2,
                    label: this.$t('extension.Promotion_income')
                }],
                detail: null,
                contribution: null,
                detailFilter: {
                    page: 1,
                    id:0,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                profile:null,
                contributionFilter: {
                    first: 5,
                    page: 1,
                    uid:0,
                    item_type: 'recommend',
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                contributionFinished: false,
                detailIsFinished: false,
            };
        },
        apollo: {
            profile: {
                query: gql`query{profile{id account nickname tel parent{id nickname account}}}`,
                update({
                    profile
                }) {
                    return profile;
                },
            },
            detail: {
                 query: gql`query chiReferrals($page:Int $id:ID $sort:[ChiReferralsSortOrderByClause!]){detail:chiReferrals(page:$page id:$id sort:$sort){data{nickname created_at id children_count creating_revenue descendants_count speces_sum tel parent{id nickname created_at}} paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    let that = this;
                    that.detailFilter.id=that.$route.query.id;
                    return that.detailFilter;    
                },
                update({
                    detail
                }) {
                    let that = this;
                    that.detailFilter.id= that.id ;
                    that.detailFilter.page = detail.paginatorInfo.currentPage;
                    if (detail.paginatorInfo.lastPage == detail.paginatorInfo.currentPage) {
                        that.detailIsFinished = true;
                    }
                    return detail;
                },
            },
            contribution: {
                 query: gql`query contribution($page:Int $uid:ID $sort:[ContributionSortOrderByClause!]){contribution:contribution(page:$page uid:$uid  sort:$sort){data{id uid contributor title balance_origin balance_to amount user{ id tel nickname} created_at}paginatorInfo{lastPage currentPage perPage }}}`,
                variables() {
                    let that = this;
                    that.contributionFilter.uid=that.$route.query.id;
                    return that.contributionFilter;
                },
                update({
                    contribution
                }) {
                    let that = this;
                    that.contributionFilter.page = contribution.paginatorInfo.currentPage;
                    if (contribution.paginatorInfo.lastPage == contribution.paginatorInfo.currentPage) {
                        that.contributionFinished = true;
                    }
                    return contribution;
                },
            },
            
        },
        mounted() {},
        destroyed() {},
        methods: {
            onSwiperChange(from, to) {
                this.current = to
            },
            onTabChange(item, index) {
                this.$refs.swiper.goto(index)
            },
            seeExa(id) {
                  let detail_id = id  //把id重新赋值
                     //这里就是跳转name: "Bill"是指跳转到Bill页面上面
                     //query: { id: detail_id}就是我们跳转过去需要带的参数
                     this.$router.push({ name: "Extensionchi", query: { id: detail_id} });  
            },
            
            async contributionRefreshing() {
                let that = this;
                that.contributionFilter.page = 1;
                await that.$apollo.queries.contribution.refresh();
                that.$refs.contributionScrollView.finishRefresh();
            },
            
            contributionReached() {
                let that = this;
                if (that.contributionFinished == true) {
                    that.$refs.contributionScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.contribution.fetchMore({
                    variables: {
                        ...that.contributionFilter,
                        page: that.contributionFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        contribution: previousResult
                    }, {
                        fetchMoreResult: {
                            contribution: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.contributionScrollView.finishLoadMore();
                        return {
                            contribution: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },

            async onRefreshing() {
                let that = this;
                 that.detailFilter.id= that.id ;
                that.detailFilter.page = 1;
                that.detailFilter.id=that.id,
                await that.$apollo.queries.detail.refresh();
                that.$refs.detailScrollView.finishRefresh();
            },
            onEndReached() {
                let that = this;
                if (that.detailIsFinished == true) {
                    that.$refs.detailScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.detail.fetchMore({
                    variables: {
                        ...that.detailFilter,
                        id: that.id ,
                        page: that.detailFilter.page + 1,
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        detail: previousResult
                    }, {
                        fetchMoreResult: {
                            detail: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.detailScrollView.finishLoadMore();
                        return {
                            detail: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
    .md-scroll-view {
        height: 72vh;
    }
    
    .scroll-view-container {
        padding-bottom: 1rem;
    }
    
    .md-tab-bar {
        position: relative;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        background-color: #17172a;
    }
    .md-scroll-view {
        height: 85vh;
    }
    .md-swiper{
         height: 85vh;
    }
</style>

<template>
    <div class="home">

        <nav-bar :title="$t('common.Home')" fixed>
            <!--    <template #left v-if="isAndroid">
                <a href="http://47.242.233.90:8888/down/l8VLEGrk7ck4"
                    style=" background: rgb(9 144 184);padding: 0.5rem 1rem;border-radius: 0.5rem;">{{$t('public.Download')}}</a>
            </template> -->
            <template #right>
                <div @click="isLangShow=true">
                    {{$i18n.locale.toUpperCase()}}
                </div>
            </template>
        </nav-bar>
        <md-selector v-model="isLangShow" default-value="2"
            :data="[{value: 'zh',text: '中文',},{value: 'en',text: 'english',}]" max-height="320px"
            :title="$t('common.SelectLang')" large-radius @choose="onSelectorChoose">
        </md-selector>

        <div class="balance-box">
            <div class="balance">
                <div class="item" v-if="coinname==='Fic'">
                    <p style="font-size:1.5rem;"
                        :class="{red:ficUsdt.percent_change>=0,green:ficUsdt.percent_change < 0,}">
                        ${{ficUsdt.last.toFixed(2)}}</p>
                    <p v-if="usdtCny" style="font-size:0.75rem;"
                        :class="{red:ficUsdt.percent_change>=0,green:ficUsdt.percent_change < 0,}">
                        {{(ficUsdt.last*usdtCny.last).toFixed(2)}} CNY
                    </p>

                </div>

                <div class="item" v-else>
                    <p style="font-size:1.5rem;"
                        :class="{red:filUsdt.percent_change>=0,green:filUsdt.percent_change < 0,}">
                        ${{filUsdt.last.toFixed(2)}}</p>
                    <p v-if="usdtCny" style="font-size:0.75rem;"
                        :class="{red:filUsdt.percent_change>=0,green:filUsdt.percent_change < 0,}">
                        {{(filUsdt.last*usdtCny.last).toFixed(2)}} CNY
                    </p>
                </div>


                <div class="item" style="display: flex;flex-direction: row;align-items: center;">
                    <img src="@/assets/images/filecoin.png" style="width:3rem;margin-right: 0.5rem;" />
                    <h3>{{allcoinname}}</h3>
                </div>
            </div>

            <div class="chat" v-if="coinname==='Fic'" style="position: relative">
                <line-chat class="chat-inner" :styles="myStyles" :chart-data="{
              labels:ficUsdtList.map((item)=>{return item.created_at.substr(11,5)}),
              datasets:[
                {
                  label: $t('home.now_price'),
                  pointRadius:0,fill: 'start',lineTension: 0,
                  borderWidth:1,
                  position: 'right',
                  borderColor:'rgba(255, 255, 255, 1.0)',
                  backgroundColor:'rgba(255, 255, 255, 0.2)',
                  data: ficUsdtList.map((item)=>{return {x:item.created_at.substr(11,5),y:item.last}})
                }
              ],
            }" :options="{
              legend:{
                display:false,
              },
            }" />
            </div>


            <div class="chat" v-else style="position: relative">
                <line-chat class="chat-inner" :styles="myStyles" :chart-data="{
          labels:filUsdtList.map((item)=>{return item.created_at.substr(11,5)}),
          datasets:[
            {
              label: $t('home.now_price'),
              pointRadius:0,fill: 'start',lineTension: 0,
              borderWidth:1,
              position: 'right',
              borderColor:'rgba(255, 255, 255, 1.0)',
              backgroundColor:'rgba(255, 255, 255, 0.2)',
              data: filUsdtList.map((item)=>{return {x:item.created_at.substr(11,5),y:item.last}})
            }
          ],
        }" :options="{
          legend:{
            display:false,
          },
        }" />
            </div>
        </div>
        <div class="balance-box">
            <h3>{{$t('report.fil_balance')}}{{coinname}}</h3>
            <hr />
            <div class="balance">
                <div class="item">
                    <p>{{$t('report.fil_balance')}}</p>
                    <p v-if="profile">
                        <img class="img-icon" src="@/assets/images/filecoin.png" /> {{(profile.fil_balance).toFixed(4)}}
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.fil_lock')}}</p>
                    <p v-if="profile">
                        <img class="img-icon" src="@/assets/images/filecoin.png" />
                        {{(profile.space_unallocated_income_sum + profile.space_deposit_sum).toFixed(4)}}
                    </p>
                </div>
            </div>
        </div>
        <div class="balance-box">
            <h3>{{$t('report.sectors_size')}}</h3>
            <hr />
            <div class="balance">
                <div class="item">
                    <p>{{$t('report.sectors_size')}}</p>
                    <p>
                        {{spaceTotal}} T
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.my_sectors_size')}}</p>
                    <p v-if="profile">
                        {{profile.space_sum}} T
                    </p>
                </div>
            </div>
        </div>
        <div class="balance-box">
            <h3>{{$t('report.deposit')}}</h3>
            <hr />
            <div class="balance">
                <div class="item">
                    <p>{{$t('report.space_deposit_sum')}}</p>
                    <p v-if="profile">
                        <img class="img-icon" src="@/assets/images/filecoin.png" /> {{(profile.space_deposit_sum).toFixed(4)}}
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.space_unallocated_income_sum')}}</p>
                    <p v-if="profile">
                        <img class="img-icon" src="@/assets/images/filecoin.png" />
                        {{(profile.space_unallocated_income_sum).toFixed(4)}}
                    </p>
                </div>
            </div>
        </div>
        <div class="balance-box" style="position: relative">
            <div class="chat" v-if="billReport">
                <line-chat class="chat-inner" :styles="myStyles" :chart-data="{
          labels:billReport.values.map((item)=>{return item.label}),
          datasets:[
            {
              label: $t('report.statistics'),
              pointRadius:0,fill: 'start',lineTension: 0,
              borderWidth:1,
              position: 'right',
              borderColor:'rgba(255, 255, 255, 1.0)',
              backgroundColor:'rgba(255, 255, 255, 0.2)',
              data: billReport.values.map((item)=>{return {x:item.label,y:item.value} })
            }
          ],
        }" :options="{
        }" />
            </div>
        </div>
        <main-bar current="home" />
    </div>
</template>

<script>
    import MainBar from '@/components/MainBar.vue'
    import gql from 'graphql-tag'
    import LineChat from '@/components/LineChat.js'
    import NavBar from '@/components/Nav'
    import {
        setLang
    } from '@/i18n'
    export default {
        name: 'Home',
        components: {
            MainBar,
            LineChat,
            NavBar,
        },
        data() {
            return {
                isAndroid: null,
                height: 200,
                usdtCny: null,
                filUsdt: null,
                ficUsdt: null,
                filUsdtList: null,
                ficUsdtList: null,
                billCountReport: null,
                billReport: null,
                spaceTotal: 0,
                isLangShow: false,
            };
        },
        apollo: {
            usdtCny: {
                query: gql`query tickerLast($code:String!){usdtCny:tickerLast(code:$code){id code base_volume high24hr highest_bid last low24hr lowest_ask percent_change quote_volume}}`,
                variables: {
                    code: 'usdt_cny'
                },
            },
            filUsdt: {
                query: gql`query tickerLast($code:String!){filUsdt:tickerLast(code:$code){id code base_volume high24hr highest_bid last low24hr lowest_ask percent_change quote_volume}}`,
                variables: {
                    code: 'fil_usdt'
                },
            },




            filUsdtList: {
                query: gql`query tickerLast($code:String!){filUsdtList:tickerLastList(code:$code,limit:64){id code base_volume high24hr highest_bid last low24hr lowest_ask percent_change quote_volume created_at}}`,
                variables: {
                    code: 'fil_usdt'
                },
            },



            ficUsdt: {
                query: gql`query tickerLast($code:String!){ficUsdt:tickerLast(code:$code){id code base_volume high24hr highest_bid last low24hr lowest_ask percent_change quote_volume}}`,
                variables: {
                    code: 'fic'
                },
            },


            ficUsdtList: {
                query: gql`query tickerLast($code:String!){ficUsdtList:tickerLastList(code:$code,limit:64){id code base_volume high24hr highest_bid last low24hr lowest_ask percent_change quote_volume created_at}}`,
                variables: {
                    code: 'fic'
                },
            },




            profile: {
                query: require('@/graphql/queries/profile.gql'),
            },
            billCountReport: {
                query: gql`query billCountReport($types:[String!]!){billCountReport:userBillCountReport(types:$types){name label value}}`,
                variables: {
                    types: ['space_income']
                },
            },
            billReport: {
                query: gql`query billReport($types:[String!]!){billReport:userBillReport(types:$types){name label values{name label value}}}`,
                variables: {
                    types: ['space_income']
                },
            },
            spaceTotal: {
                query: gql`query spaceTotal{spaceTotal}`,
            },
        },
        mounted() {

            let that = this;
            that.updateInterval = setInterval(() => {
                that.$apollo.queries.filUsdt.refresh();
                that.$apollo.queries.usdtCny.refresh();
                that.$apollo.queries.filUsdtList.refresh();

                that.$apollo.queries.ficUsdt.refresh();
                that.$apollo.queries.ficUsdtList.refresh();
            }, 5000);
            that.billReportInterval = setInterval(() => {
                that.$apollo.queries.billReport.refresh();
            }, 10000);
            var u = navigator.userAgent;
            that.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
        },
        destroyed() {
            clearInterval(this.updateInterval);
            clearInterval(this.billReportInterval);
        },
        methods: {
            increase() {
                this.height += 10
            },
            onSelectorChoose({
                value
            }) {
                setLang(value);
            },
        },
        computed: {
            myStyles() {
                return {
                    height: '${this.height}px',
                    position: 'relative'
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 3.5rem 0.6rem;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
            margin-top: -3px;
        }

        .red {
            color: red !important;
        }

        .green {
            color: green !important;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;

                * {
                    vertical-align: middle;
                }
            }
        }
    }

    .chat {
        background-color: transparent;
        position: relative;
        // padding:10px;
        overflow-x: scroll;

        .chat-inner {
            position: relative;
            width: 100%;
            height: 22rem;
        }
    }

    #line-chart {
        position: relative
    }
</style>

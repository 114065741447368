import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/profile'
// import Service from '../views/service'
import Withdraw from '../views/withdraw'
import Recharge from '../views/recharge'
import Detail from '../views/detail'
import Bill from '../views/bill'
import Download from '../views/download'
import Space from '../views/space'
import Extension from '../views/extension'
import Extensionchi from '../views/extensionchi'
import Extensioninfo from '../views/extensioninfo'
import Iframe from '../views/iframe'
import Orderlist from '../views/orderlist'
import Report from '../views/report'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'首页',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta:{
      title:'个人中心',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: Report,
    meta:{
      title:'算力',
    },
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    meta:{
      title:'提现',
    },
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: Recharge,
    meta:{
      title:'充值',
    },
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    component: Orderlist,
    meta:{
      title:'算力购买订单',
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail,
    meta:{
      title:'收益明细',
    },
  },
  {
    path: '/bill/:id',
    name: 'Bill',
    component: Bill,
    meta:{
      title:'详情',
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta:{
      title:'详情',
    },
  },
  {
    path: '/space',
    name: 'Space',
    component: Space,
    meta:{
      title:'矿池公开页',
    },
  },
  {
    path: '/extension',
    name: 'Extension',
    component: Extension,
    meta:{
      title:'我的推广',
    },
  },
  {
    path: '/extensionchi',
    name: 'Extensionchi',
    component: Extensionchi,
    meta:{
      title:'我的推广',
    },
  },
  
  {
    path: '/extensioninfo',
    name: 'Extensioninfo',
    component: Extensioninfo,
    meta:{
      title:'收益明细',
    },
  },
  
  
  {
    path: '/iframe',
    name: 'Iframe',
    component: Iframe,
    meta:{
      title:'我的推广',
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

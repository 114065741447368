<template>
    <div class="recharge-page">
        <nav-bar :title="$t('profile.recharge')" fixed>
            <template #left>
                <div @click="$router.push('/profile');">
                    <md-icon name="arrow-left" />{{$t('public.return')}}
                </div>
            </template>
        </nav-bar>

        <md-tab-bar v-model="current" :items="items" style="background-color: #181729;" @change="tapChange"
            v-if="coinname !=='Fic'" />
        <md-field>
            <div v-if="current===1">
                <h4 style="text-align: center;">{{$t('recharge.Coll_account')}}</h4>
                <vue-qr logoSrc="https://gao.sunfil.app/storage/xwG6RrQQLQBJQYoFERWp8VND5xHlSWxSk5xBjC9D.png"
                    :text="codr.value" style="display: block;width: 200px; border-radius: 10px;margin: 10px auto;">
                </vue-qr>
                <md-button type="primary" size="large" style="font-size: 0.9rem;background: none; box-shadow: none;"
                    v-if="codr.value" @click="copyUrl(codr.value)">
                    <md-icon name="security" color="#1096c2"></md-icon>{{codr.value}}
                </md-button>
                <md-input-item type="text" :title="$t('recharge.Transfer_ID')"
                    :placeholder="$t('recharge.fill_Transfer_ID')" style="margin-bottom: 1rem;"
                    v-model="rechargeForm.order_id" />
                <div style="display: flex;flex-direction: row;justify-items: flex-end;justify-content: space-between;">
                    <md-button type="default" @click="rechargeConfirm">{{$t('public.submit')}}
                    </md-button>
                </div>
            </div>

            <div v-if="current===2  ">
                <md-input-item type="text" :title="$t('recharge.rechargefil')"
                    :placeholder="$t('recharge.fill_rechargefil')" style="margin-bottom: 1rem;"
                    v-model="InFilrechargeForm.amount" />
                <div style="display: flex;flex-direction: row;justify-items: flex-end;justify-content: space-between;">
                    <md-button type="default" @click="InFilRechargeConfirm">{{$t('public.submit')}}
                    </md-button>
                </div>
          <!--      <a :href="'infilpay://'+pages/chat/index223344">H5调起APP</a>
                <p @click="sendMs">uni.postMessage</p> -->

            </div>
        </md-field>
        <md-scroll-view class="scrollView" ref="rechargesScrollView" :style="'height: '+vh" :scrolling-x="false"
            @end-reached="rechargeEndReached" @refreshing="rechargeRefreshing" v-if="recharges">
            <md-scroll-view-refresh slot="refresh" />
            <md-bill v-for="item in recharges.data" :key="item.id" :no="item.sn">
                <md-detail-item :title="$t('recharge.Transfer_ID')" v-if="item.order_id">
                    <div v-if="item.type==='user'">
                        <a :href="'https://filfox.info/zh/message/'+item.order_id" target="_blank">
                            {{item.order_id}}
                        </a>
                    </div>
                    <div v-else>
                        {{item.order_id}}
                    </div>
                </md-detail-item>
                <md-detail-item :title="$t('recharge.Recharge_type')">
                    {{typeMap[item.type]}}
                </md-detail-item>
                <md-detail-item :title="$t('public.num')">
                    {{item.amount}}{{coinname}}
                </md-detail-item>
                <md-detail-item :title="$t('public.status')">
                    <span v-bind:style="{ color: {normal:'inherit',success:'green',failed:'red'}[item.status] }">
                        {{statusMap[item.status]}}
                    </span>
                </md-detail-item>
                <md-detail-item :title="$t('public.cre_time')">
                    {{item.created_at}}
                </md-detail-item>
            </md-bill>
            <md-result-page type="empty" class="customized" v-if="recharges && recharges.paginatorInfo.count==0"
                :text="$t('public.No_information')"></md-result-page>
            <md-scroll-view-more slot="more" :is-finished="rechargesIsFinished" />
        </md-scroll-view>

    </div>
</template>
<script type="text/javascript" src="//js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.0.1.52.js"></script>
<script>
    import gql from 'graphql-tag';
    import NavBar from '../../components/Nav';
    import vueQr from 'vue-qr'

    import {
        getGqlValidFirstErr
    } from '../../utils/common';
    import {
        Toast,
        Dialog
    } from 'mand-mobile'
    export default {
        name: "qrCode",
        components: {
            NavBar,
            vueQr,
        },
        data() {
            return {
                vh: '32vh',
                current: 1,
                items: [{
                    name: 1,
                    label: this.$t('recharge.Recharge'),
                }, {
                    name: 2,
                    label: this.$t('recharge.InFilRecharge'),
                }],
                search: {
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                recharges: null,
                settings: null,
                rechargesIsFinished: false,
                rechargeShow: false,
                rechargeForm: {},
                InFilrechargeForm: {},
                statusMap: {
                    normal: this.$t('public.normal'),
                    unpaid: this.$t('public.unpaid'),
                    success: this.$t('public.success'),
                    failed: this.$t('public.failed'),
                },
                typeMap: {
                    user: this.$t('public.user'),
                    system: this.$t('public.system'),
                    userinfil: this.$t('public.userinfil'),
                },
                collection_account: '',
                codr: {
                    name: '',
                    value: '',
                },
                imageUrl: '../../assets/images/logo.png',
            };
        },

        apollo: {
            recharges: {
                query: gql`query recharges( $sn:String $status:[String] $order_id:String $created_at:DateRange $sort:[UserRechargesSortOrderByClause!]){recharges:userRecharges (sn:$sn  status:$status order_id:$order_id created_at:$created_at sort:$sort){data{id sn type amount order_id status created_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.search;
                },
                update({
                    recharges
                }) {
                    let that = this;
                    that.search.page = recharges.paginatorInfo.currentPage;
                    if (recharges.paginatorInfo.lastPage == recharges.paginatorInfo.currentPage) {
                        that.rechargesIsFinished = true;
                    }
                    return recharges;
                },
            },
            settings: {
                query: gql`query settings($names:[String!]!){settings(names:$names){name value}}`,
                variables: {
                    names: ['collection_account'],
                },
                update({
                    settings
                }) {
                    let that = this;
                    that.codr.name = settings[0].name;
                    that.codr.value = settings[0].value;
                    return settings;

                }
            },
        },

        mounted() {
           document.addEventListener('UniAppJSBridgeReady', function() {  
               console.log('111')
               uni.getEnv(function(res) {  
                   if (res.plus) {  
                       console.log('当前环境为【5+App】');  
                   } else if (res.miniprogram) {  
                       console.log('当前环境为【微信小程序】');  
                   }  
               });  
           });
        },
        methods: {
            sendMs() {
                window.postMessage('Hello World!','http://example.com');
            },

            tapChange(index) {
                if (index.name === 2) {
                    this.vh = '68vh'
                }
            },
            async rechargeRefreshing() {
                let that = this;
                that.search.page = 1;
                await that.$apollo.queries.recharges.refresh();
                that.$refs.rechargesScrollView.finishRefresh();
            },
            async rechargeEndReached() {
                let that = this;
                if (that.rechargesIsFinished == true) {
                    that.$refs.rechargesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.recharges.fetchMore({
                    variables: {
                        ...that.search,
                        page: that.search.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        recharges: previousResult
                    }, {
                        fetchMoreResult: {
                            recharges: fetchMoreResult
                        }
                    }) {
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.rechargesScrollView.finishLoadMore();
                        return {
                            recharges: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
            rechargeConfirm() {
                let that = this;
                Dialog.confirm({
                    title: this.$t('public.Notes'),
                    content: this.$t('recharge.Notes'),
                    onConfirm() {
                        that.recharge();
                    }
                });
            },


            async InFilRechargeConfirm() {
                let that = this;
                try {
                    await that.$apollo.mutate({
                        mutation: gql`mutation createreInFilcharge($type:String! $wallet:String! $amount:Int!){createInFilrecharge:userCreateInFilRecharge(type:$type wallet:$wallet amount:$amount)}`,
                        variables: {
                            ...that.InFilrechargeForm,
                            type: 'userinfil',
                            wallet: 'FIL'
                        },

                    }).then((data) => {
                       let environment= localStorage.getItem('environment')
                        if(environment==='APP'){
                            console.log('app')
                            window.open('infilpay://'+data.data.createInFilrecharge[1], '_self')
                        }else{
                             console.log('H5')
                            window.open(data.data.createInFilrecharge[0], '_self')
                        }

                    })

                    // that.$refs.rechargesScrollView.triggerRefresh();
                    that.rechargeForm = {};

                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                    throw e;
                }
            },

            copyUrl(url) {
                var domUrl = document.createElement("input");
                domUrl.value = url;
                domUrl.id = "creatDom";
                document.body.appendChild(domUrl);
                domUrl.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                let creatDom = document.getElementById("creatDom");
                creatDom.parentNode.removeChild(creatDom);
                Toast.hide();
                Toast.succeed(this.$t('public.copy_don'));
            },
            async recharge() {
                let that = this;
                Toast.loading(this.$t('recharge.Submitting'));
                try {
                    await that.$apollo.mutate({
                        mutation: gql`mutation createrecharge($type:String! $wallet:String! $order_id:String!){createrecharge:userCreateRecharge(type:$type wallet:$wallet order_id:$order_id){id sn}}`,
                        variables: {
                            ...that.rechargeForm,
                            type: 'user',
                            wallet: 'FIL'
                        },
                    });
                    that.$refs.rechargesScrollView.triggerRefresh();
                    that.rechargeShow = false;
                    that.rechargeForm = {};
                    Toast.hide();
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                    throw e;
                }
            },
        },
        // mounted: function() {
        //     this.$nextTick(function() {
        //         this.bindQRCode();
        //     })
        // },
    }
</script>
<style lang="scss" scoped>
    .recharge-page {
        color: #fff;
        z-index: 999;
        position: relative;
        padding: 3.5rem 0.5rem 0 0.5rem;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 0.5rem;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;

                >* {
                    vertical-align: middle;
                }
            }
        }
    }

    .qrCode {
        margin: 1rem auto;
        display: block;
        width: 9.4rem;
        height: 9.4rem;
        background: white;
        border-radius: 1rem;
        margin-bottom: 100px;
        overflow: hidden;
        padding: 1.5rem;
    }


    .inner-popup {
        .md-popup-box {
            height: 70%;
        }
    }
</style>

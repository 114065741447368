<template>
    <div class="withdraw-page">
        <nav-bar :title="$t('profile.withdraw')" fixed>
            <template #left>
                <div @click="$router.back();">
                    <md-icon name="arrow-left" /> {{$t('public.return')}}
                </div>
            </template>
            <template #right>
                <div @click="withdrawShow=true">
                    {{$t('profile.withdraw')}}
                    <md-icon name="rmb" />
                </div>
            </template>
        </nav-bar>
        <md-popup class="inner-popup" v-model="withdrawShow" position="bottom">
            <md-popup-title-bar only-close large-radius :title="$t('profile.withdraw')" title-align="left"
                @cancel="withdrawShow=false">
            </md-popup-title-bar>
            <md-field>
                <md-detail-item :title="$t('login.mobile')" :content="profile.tel.substr(0,3)+'******'+profile.tel.substr(-3,3)"/>
                <md-input-item :title="$t('login.verification')" v-model="withdrawForm.smscode" class="wt" />
                <div @click="sendsms" class="sendbt">{{btntxt}}</div>
                <md-input-item type="text" :title="$t('withdraw.account')" :placeholder="allcoinname+$t('withdraw.Withdrawal')"
                    v-model="withdrawForm.account" />
            <!--    <md-input-item type="money" v-model="withdrawmoney" :title="$t('withdraw.With_num1')" :placeholder="$t('withdraw.With_num2')+':'+profile.fil_balance"  /> -->
                
 <md-input-item type="money" v-model="withdrawmoney" :title="$t('withdraw.With_num1')"  :placeholder="$t('withdraw.With_num2')+':'+profile.fil_balance" is-amount 
                is-formative is-virtual-keyboard @change="onNumberEnter(withdrawmoney,profile.fil_balance)">
            </md-input-item>
                
                
                
                    <div @click="withdrawmax(profile.fil_balance)" class="sendbt2">{{btntxt2}}</div>
                <md-button type="primary" @click="withdrawConfirm">{{$t('public.submit2')}}</md-button>
            </md-field>
        </md-popup>
        <md-scroll-view class="scrollView" ref="withdrawsScrollView" :scrolling-x="false"
            @end-reached="withdrawEndReached" @refreshing="withdrawRefreshing" v-if="withdraws">
            <md-scroll-view-refresh slot="refresh" />
            <md-bill v-for="item in withdraws.data" :key="item.id" :no="item.sn">
                <md-detail-item :title="$t('withdraw.Collection_account')">
                    {{item.account}}
                </md-detail-item>
                <md-detail-item :title="$t('withdraw.Transfer_ID')" v-if="item.order_id">
                    
                    <a :href="'https://filfox.info/zh/message/'+item.order_id" target="_blank" v-if="coinname==='Fil'">
                        {{item.order_id}}
                    </a>
                    
                    <a :href="'https://fic.tokenview.com/cn/tx/'+item.order_id" target="_blank" v-else>
                        {{item.order_id}}
                    </a>
                    
                    
                </md-detail-item>
                <md-detail-item :title="$t('public.num')">
                    {{item.amount}}
                </md-detail-item>
                <md-detail-item :title="$t('public.status')">
                    <span v-bind:style="{ color: {normal:'inherit',success:'green',failed:'red'}[item.status] }">
                        {{statusMap[item.status]}}
                    </span>
                </md-detail-item>
                <md-detail-item :title="$t('public.cre_time')">
                    {{item.created_at}}
                </md-detail-item>
            </md-bill>
            <md-result-page v-if="withdraws && withdraws.paginatorInfo.count==0"  :text="$t('public.No_information')"></md-result-page>
            <md-scroll-view-more slot="more" :is-finished="withdrawsIsFinished" />
        </md-scroll-view>
    </div>
</template>
<script>
    import gql from 'graphql-tag';
    import NavBar from '../../components/Nav';
    import {
        getGqlValidFirstErr
    } from '../../utils/common';
    import {
        Toast,
        Dialog
    } from 'mand-mobile'
    export default {
        components: {
            NavBar
        },
        data() {
            return {
                show: true,
                disabled: false,
                withdrawmoney:'',
                time: 5,
                btntxt: this.$t('login.sendsms'),
                btntxt2: this.$t('withdraw.With_num2'),
                search: {
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                withdraws: null,
                withdrawsIsFinished: false,
                withdrawShow: false,
                withdrawForm: {},
                statusMap: {
                    normal: this.$t('public.normal'),
                    success: this.$t('public.success'),
                    failed: this.$t('public.failed')
                },
            };
        },
        apollo: {
            
            
            profile: {
                query: gql`query{profile{id account nickname fil_balance tel nationality parent{id nickname account}}}`,
                update({
                    profile
                }) {
                    return profile;
                },
            },
                
            withdraws: {
                query: gql`query withdraws(
                $sn:String $type:String $account:String $status:[String] $order_id:String $created_at:DateRange $sort:[UserWithdrawsSortOrderByClause!])
                {withdraws:userWithdraws(
                sn:$sn type:$type account:$account status:$status order_id:$order_id created_at:$created_at sort:$sort)
                {data{id sn operator{nickname}type account amount wallet order_id status created_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.search;
                },
                update({
                    withdraws
                }) {
                    let that = this;
                    that.search.page = withdraws.paginatorInfo.currentPage;
                    if (withdraws.paginatorInfo.lastPage == withdraws.paginatorInfo.currentPage) {
                        that.withdrawsIsFinished = true;
                    }
                    return withdraws;
                },
            }
        },
        methods: {
            async withdrawRefreshing() {
                let that = this;
                that.search.page = 1;
                await that.$apollo.queries.withdraws.refresh();
                that.$refs.withdrawsScrollView.finishRefresh();
            },
            async withdrawEndReached() {
                let that = this;
                if (that.withdrawsIsFinished == true) {
                    that.$refs.withdrawsScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.withdraws.fetchMore({
                    variables: {
                        ...that.search,
                        page: that.search.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        withdraws: previousResult
                    }, {
                        fetchMoreResult: {
                            withdraws: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.withdrawsScrollView.finishLoadMore();
                        return {
                            withdraws: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
            onNumberEnter(val,max) {
                console.log(val)
                if(val >max){
                     Toast.info('max:'+max)
                     this.ygsy = val = this.withdrawmoney = max
                }else{
                    if (val === null || val === '') {
                        val = 0;
                    }
                    this.ygsy = val;
                }
            },
            withdrawmax(num){
                this.withdrawmoney=num
            },
            withdrawConfirm() {
                let that = this;
                Dialog.confirm({
                    title: this.$t('common.ok'),
                    content: this.$t('withdraw.Address_confirmation') + that.withdrawForm.account,
                    onConfirm() {
                        that.withdraw();
                    }
                });
            },
            async withdraw() {
                let that = this;
                Toast.loading('提交中...');
                try {
                    that.withdrawForm.tel=that.profile.tel;
                    that.withdrawForm.amount=that.withdrawmoney;
                    await that.$apollo.mutate({
                        mutation: gql`mutation createWithdraw($type:String! $wallet:String! $tel:String,  $smscode:String   $account:String! $amount:Float!){createWithdraw:userCreateWithdraw(type:$type wallet:$wallet account:$account amount:$amount tel:$tel smscode:$smscode){id sn}}`,
                        variables: {
                            ...that.withdrawForm,
                            type: 'FIL',
                            wallet: 'FIL'
                        },
                    });
                    that.$refs.withdrawsScrollView.triggerRefresh();
                    that.withdrawShow = false;
                    that.withdrawForm = {};
                    Toast.hide();
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                    throw e;
                }
            },
            async sendsms() {
               
                let that = this;
                this.time = 60;
                this.timer();
                try {
                    let data = {
                        tel: that.profile.tel,
                        nationality: that.profile.nationality,
                    };
            
                    await that.$apollo.mutate({
                        mutation: gql`mutation sendsms($tel:String!,$nationality:String!){
                        sendsms(tel:$tel,nationality:$nationality)}`,
                        variables: data,
                    });
            
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.failed(msg ? msg : e.message);
                }
            
            },
            timer() {
                if (this.time > 0) {
                    this.disabled = true;
                    this.time--;
                    this.btntxt = this.time + "秒";
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 0;
                    this.btntxt = "发送验证码";
                    this.disabled = false;
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    .withdraw-page {
        color: #fff;
        z-index: 999;
        position: relative;
        padding: 3.5rem 0.5rem;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);

        }
    }
    .inner-popup {
        .md-popup-box {
            height: 70%;
        }
    }
    
    .sendbt {
        width: 25%;
        position: absolute;
        padding: 0 1rem;
        background-color: #2f86f6;
        border-radius: 0.125rem;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
        margin-top: -2.5rem;
        right: 1rem;
    }
    
    .sendbt2 {
        width: 15%;
        position: absolute;
        padding: 0 1rem;
        background-color: #2f86f6;
        border-radius: 0.125rem;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
        margin-top: -3.2rem;
        right: 1rem;
    }
</style>

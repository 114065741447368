<template>
    <div class="recharge-page">
        <div class="home">
            <nav-bar :title="$t('profile.detail')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
          <div class="balance-box">
              <h3>{{$t('detail.Income_details')}}</h3>
              <hr />
              <div class="balance">
                   <md-detail-item :title="$t('detail.Total_revenue')" :content="incomedetails[0]+coinname" />
                    <md-detail-item :title="$t('detail.Allocated')" :content="incomedetails[1]+coinname" />
                     <md-detail-item :title="$t('detail.Not_yet_allocated')" :content="incomedetails[2]+coinname" />
              </div>
          </div>
            <md-scroll-view ref="detailScrollView" :scrolling-x="false" @end-reached="onEndReached" @refreshing="onRefreshing" v-if="detail" >
                <md-scroll-view-refresh slot="refresh" />
                <div v-for="detail in detail.data" :key="detail.id" @click="seeExa(detail.id)" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                    <header class="md-bill-header" style="padding: 0.5rem 0;">
                        <div class="md-bill-no">{{detail.created_at}}</div>
                        <div class="md-bill-no" style="background:rgb(44 43 82); padding: 0.2rem 0.5rem; border-radius: 0.5rem;">{{$t('public.details')}}</div>
                    </header>
                    <div class="md-bill-neck"><span></span></div>
                    <md-detail-item :title="$t('detail.Total_revenue')" :content="detail.income+coinname" />
                    <md-detail-item :title="$t('detail.Allocated')" :content="detail.allocated_income+coinname" />
                    <md-detail-item :title="$t('detail.Not_yet_allocated')" :content="detail.surplus_income+coinname"/>
                    <md-detail-item :title="$t('detail.allocated_day')" :content="detail.allocated_day+$t('detail.Days')" />
                    <md-detail-item :title="$t('detail.surplus_day')" :content="detail.surplus_day+$t('detail.Days')" />
                    <md-detail-item :title="$t('public.status')" :content="statusMap[detail.status]" />
                </div>
                 <md-result-page type="empty" class="customized" v-if="detail && detail.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
                <md-scroll-view-more slot="more" :is-finished="detailIsFinished" />
            </md-scroll-view>
        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    export default {
        name: 'Detail',
        components: {
            NavBar,
        },
        data() {
            return {
                detail: null,
                incomedetails: {
                    Total_revenue: 0,
                    Allocated: 0,
                    Not_yet_allocated: 0
                },
                detailFilter: {
                    first:5,
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                detailIsFinished: false,
                statusMap: {
                    active: this.$t('public.active'),
                    completed: this.$t('public.completed'),
                },
            };
        },
        apollo: {
            detail: {
                query: gql`query userDetails( $page:Int $first:Int $sort:[UserDetailsSortOrderByClause!]){detail:userDetails(page:$page first:$first sort:$sort){data{id uid total income allocated_income surplus_income allocated_day surplus_day status created_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.detailFilter;
                },
                update({
                    detail
                }) {
                    let that = this;
                    that.detailFilter.page = detail.paginatorInfo.currentPage;
                    if (detail.paginatorInfo.lastPage == detail.paginatorInfo.currentPage) {
                        that.detailIsFinished = true;
                    }
                    return detail;
                },
            },

            incomedetails: {
                query: gql`query incomedetails{incomedetails}`,
            },



        },


        mounted() {},
        destroyed() {},
        methods: {
            async onRefreshing() {
                let that = this;
                that.detailFilter.page = 1;
                await that.$apollo.queries.detail.refresh();
                that.$refs.detailScrollView.finishRefresh();
            },
            seeExa(id) {
                  let detail_id = id  //把id重新赋值
                     //这里就是跳转name: "Bill"是指跳转到Bill页面上面
                     //query: { id: detail_id}就是我们跳转过去需要带的参数
                     this.$router.push({ name: "Bill", query: { id: detail_id} });  
            },
            onEndReached() {
                let that = this;
                if (that.detailIsFinished == true) {
                    that.$refs.detailScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.detail.fetchMore({
                    variables: {
                        ...that.detailFilter,
                        page: that.detailFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        detail: previousResult
                    }, {
                        fetchMoreResult: {
                            detail: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.detailScrollView.finishLoadMore();
                        return {
                            detail: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 0 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 0.5rem;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
    
    
    .md-scroll-view {
        height: 72vh;
    }
    .scroll-view-container{
        padding-bottom: 1rem;
    }
</style>

<template>
    <div class="profile">
        <!--    <div class="account" v-if="profile.account">
            {{profile.account}}
        </div> -->
        <div class="profile-box" v-if="profile">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <img src="../../assets/images/logo.png" class="loading-animation avatar" />
            </div>

            <div class="info-list">
                <div class="info-list-item">
                    <h1>{{profile.nickname}}</h1>

                </div>
                <div class="info-list-item">
                    <h5>
                        {{profile.tel.substr(0,3)}}******{{profile.tel.substr(-3,3)}}
                    </h5>
                </div>
            </div>

            <img src="../../assets/images/ewm.png" @click="$router.push('/extension')" style="width: 1.5rem;" />

        </div>
        <div class="main">
            <div class="plane" v-if="profile && profile.parent">
                {{$t('profile.referrer')}}: {{profile.parent.tel.substr(0,3)}}******{{profile.parent.tel.substr(-3,3)}}
            </div>
        </div>
        <div class="main">
            <md-field class="no_bor">
                <md-cell-item :title="$t('profile.recharge')" @click="$router.push('/recharge')" arrow />
                <md-cell-item :title="$t('profile.withdraw')" @click="$router.push('/withdraw')" arrow />
                <md-cell-item :title="$t('profile.orderlist')" @click="$router.push('/orderlist')" arrow />
                <md-cell-item :title="$t('profile.detail')" @click="$router.push('/detail')" arrow />
                <md-cell-item :title="$t('profile.changePwd')" arrow @click="showPopUp('align')" />
                <md-cell-item :title="$t('profile.logout')" arrow @click="logout" />
            </md-field>
        </div>

        <md-landscape v-model="qrcodeShow" :mask-closable="true">
            <div class="plane" v-if="qrcodeUrl" style="height: 75vh;">
                <p>
                    推广二维码
                </p>
                <div class="qrcode">
                    <img :src="qrcodeUrl" width="80%" style="border-radius: 1rem;" />
                </div>
                <md-scroll-view ref="detailScrollView" :scrolling-x="false" @end-reached="onEndReached"
                    @refreshing="onRefreshing" v-if="detail" style="height: 44vh;">
                    <md-scroll-view-refresh slot="refresh" />
                    <div v-for="detail in detail.data" :key="detail.id" class="md-water-mark md-bill"
                        style=" padding: 0.5rem 1rem;background-color:#00000052 !important;">
                        <md-detail-item :title="$t('profile.created_at')" :content="detail.created_at" />
                        <md-detail-item :title="$t('profile.nickname')" :content="detail.nickname" />
                        <md-detail-item :title="$t('profile.parentnickname')" :content="detail.parent.nickname" />
                    </div>
                    <md-scroll-view-more slot="more" :is-finished="detailIsFinished" />
                </md-scroll-view>
            </div>
        </md-landscape>

        <md-popup class="inner-popup" v-model="isPopupShow.align" position="bottom">
            <md-popup-title-bar only-close large-radius :title="$t('profile.changePwd')" title-align="left"
                @cancel="hidePopUp('align')"></md-popup-title-bar>
            <div class="md-example-popup md-example-popup-align" style="padding:0.2rem;background: #181729;">
                <md-tab-bar v-model="current" :items="items" style="background-color: #181729;" />
                <md-field>
                    <div v-if="current===1">
                        <md-input-item :placeholder="$t('common.originPwd')" v-model="reset.password" type="password">
                        </md-input-item>
                        <md-input-item :placeholder="$t('common.newPwd')" v-model="reset.newPassword" type="password">
                        </md-input-item>
                        <md-input-item :placeholder="$t('common.ChknewPwd')" v-model="reset.ChknewPassword"
                            type="password">
                        </md-input-item>
                        <md-button type="primary" @click="resetPassword(current)">{{$t('report.confirm')}}</md-button>
                    </div>

                    <div v-if="current===2">
                        <md-input-item :placeholder="$t('common.originPayPwd')" v-model="reset.pay_password"
                            type="password">
                        </md-input-item>
                        <md-input-item :placeholder="$t('common.newPayPwd')" v-model="reset.newPayPassword"
                            type="password">
                        </md-input-item>
                        <md-input-item :placeholder="$t('common.ChknewPayPwd')" v-model="reset.ChknewPayPassword"
                            type="password">
                        </md-input-item>
                        <md-button type="primary" @click="resetPassword(current)">{{$t('report.confirm')}}</md-button>
                    </div>

                    <div v-if="current===3">
                        <!-- 国籍选择 -->
                        <md-field-item :title="$t('login.nationality')" :content="selectorValue" @click="showSelector"
                            arrow solid class="wt" />
                        <!-- 电话 -->
                        <md-input-item :title="$t('login.mobile')" v-model="reset.tel" class="wt" />
                        <div @click="sendsms" class="sendbt">{{btntxt}}</div>
                        <md-input-item :title="$t('login.verification')" v-model="reset.smscode" class="wt" />
                        <md-input-item v-model="reset.nationality" class="wt" disabled="disabled"
                            style="display: none;" />
                        <md-input-item :placeholder="$t('common.newPayPwd')" v-model="reset.newPayPassword"
                            type="password">
                        </md-input-item>
                        <md-input-item :placeholder="$t('common.ChknewPayPwd')" v-model="reset.ChknewPayPassword"
                            type="password">
                        </md-input-item>

                        <md-button type="primary" @click="resetPassword(current)">{{$t('report.confirm')}}</md-button>
                    </div>


                </md-field>
            </div>
        </md-popup>

        <md-selector v-model="isSelectorShow" :data="options" style="min-height: 320px;max-height: 50%;z-index: 20000;"
            :title="$t('login.nationality')" large-radius @choose="onSelector">
            <div class="selector-header" slot="header">
            </div>
            <template slot-scope="{ option, index, selected }">
                <div class="selector-item-body" :class="{disabled: option.disabled, selected}">
                    <div class="selector-item-content">
                        <p v-if="$i18n.locale.toUpperCase() === 'ZH'">{{option.name}}</p>
                        <p v-if="$i18n.locale.toUpperCase() === 'EN'">{{option.en}}</p>
                    </div>
                </div>
            </template>
            <div class="selector-footer" slot="footer">
            </div>
        </md-selector>
        <main-bar current="profile" />
    </div>
</template>

<script>
    import MainBar from '@/components/MainBar.vue';
    import '@/assets/icon/ewm.svg';
    import gql from 'graphql-tag';
    import {
        onLogout
    } from '../../vue-apollo';
    import QRCode from 'qrcode'
    import {
        Toast,
        RadioBox,
        RadioGroup,
        Popup,
        PopupTitleBar,
        ScrollView,
        Button,
        Icon,

    } from 'mand-mobile'
    import {
        getGqlValidFirstErr
    } from '../../utils/common';
    import countrydata from '../../assets/countrydata.json'

    export default {
        name: 'Profile',
        data() {
            return {
                isPopupShow: {},
                current: 1,
                items: [{
                    name: 1,
                    label: this.$t('profile.login_pwd'),
                }, {
                    name: 2,
                    label: this.$t('profile.payment_pwd'),
                }, {
                    name: 3,
                    label: this.$t('profile.forgot_pwd'),
                }],



                selectorValue: '',
                btntxt: this.$t('login.sendsms'),
                isSelectorShow: false,

                baseUrl: window.location.protocol + '//' + window.location.host + '/m',
                qrcodeUrl: null,
                resetPasswordShow: false,
                qrcodeShow: false,
                reset: {
                    password: "",
                    newPassword: "",
                },
                forgotForm: {
                    tel: '',
                    password: '',
                    check_password: '',
                    smscode: '',
                    nationality: '',
                    type: 2,
                },
                detail: null,
                detailFilter: {
                    page: 1,
                    id: 0,
                    item_type: 'space_income',
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'ASC'
                    }]
                },
                detailIsFinished: false,
                options: [],
                time: 5,
                disabled: false,
            };
        },
        components: {
            MainBar,
            [RadioBox.name]: RadioBox,
            [RadioGroup.name]: RadioGroup,
            [Popup.name]: Popup,
            [PopupTitleBar.name]: PopupTitleBar,
            [Button.name]: Button,
            [Icon.name]: Icon,
            [ScrollView.name]: ScrollView,
        },
        apollo: {
            profile: {
                query: gql`query{profile{id account nickname tel parent{id nickname tel account}}}`,
                update({
                    profile
                }) {
                    let that = this;
                    QRCode.toDataURL(that.baseUrl + '?source_user=' + profile.id, {
                            errorCorrectionLevel: 'H'
                        })
                        .then(url => {
                            that.qrcodeUrl = url;
                        });
                    return profile;
                },
            },
            detail: {
                query: gql`query userReferrals($page:Int $sort:[UserReferralsSortOrderByClause!]){detail:userReferrals(page:$page sort:$sort){data{nickname created_at id creating_revenue parent{id nickname tel created_at}}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    let that = this;
                    that.detailFilter.id = that.$route.query.id;
                    return that.detailFilter;
                },
                update({
                    detail
                }) {
                    let that = this;
                    that.detailFilter.id = that.id;
                    that.detailFilter.page = detail.paginatorInfo.currentPage;
                    if (detail.paginatorInfo.lastPage == detail.paginatorInfo.currentPage) {
                        that.detailIsFinished = true;
                    }
                    return detail;
                },
            },
        },
        methods: {
            showPopUp(type) {
                this.$set(this.isPopupShow, type, true)
            },
            hidePopUp(type) {
                this.$set(this.isPopupShow, type, false)
            },
            onSelector({
                name,
                en,
                tel
            }) {
                this.options = countrydata;
                if (localStorage.getItem('lang') === 'zh') {
                    this.selectorValue = name;
                } else {
                    this.selectorValue = en;
                }
                if (tel == 86) {
                    this.reset.tel = '';
                    this.reset.nationality = 'domestic';
                } else {
                    this.reset.tel = '+' + tel;
                    this.reset.nationality = 'international';
                }

            },
            async sendsms() {
                let that = this;
                this.time = 60;
                this.timer();
                if (that.reset.tel.length < 8) {
                    Toast.loading(this.$t('login.checktel'));
                }
                try {
                    let data = {
                        tel: that.reset.tel,
                        nationality: that.reset.nationality,
                    };

                    await that.$apollo.mutate({
                        mutation: gql`mutation sendsms($tel:String!,$nationality:String!){
                        sendsms(tel:$tel,nationality:$nationality)}`,
                        variables: data,
                    });

                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.failed(msg ? msg : e.message);
                }

            },
            showSelector() {
                this.options = countrydata
                this.isSelectorShow = true
            },
            logout() {
                onLogout(this.$apollo.getClient());
            },
            async resetqrcoded() {

            },


            async resetPassword(current) {
                let that = this;
                Toast.loading('加载中...');
                that.reset.current = current;

                if (current === 1) {
                    try {
                        await that.$apollo.mutate({
                            mutation: gql`mutation resetPassword(
                                $password:String,
                                $newPassword:String,
                                $ChknewPassword:String,
                                $current:Int
                            ){
                                resetPassword(
                                    password:$password,
                                    newPassword:$newPassword,
                                    ChknewPassword:$ChknewPassword,
                                    current:$current
                                )
                            }`,
                            variables: that.reset,
                        });
                        that.reset = {
                            password: "",
                            newPassword: "",
                            ChknewPassword: "",
                        };
                        Toast.hide();
                        Toast.succeed('更新成功')
                        this.$set(this.isPopupShow, 'align', false)
                    } catch (e) {
                        let msg = getGqlValidFirstErr(e);
                        Toast.hide();
                        Toast.failed(msg ? msg : e.message);
                    }
                } else if (current === 2) {
                    try {
                        await that.$apollo.mutate({
                            mutation: gql`mutation resetPassword(
                                $pay_password:String,
                                $newPayPassword:String,
                                $ChknewPayPassword:String,
                                $current:Int
                            ){
                                resetPassword(
                                    pay_password:$pay_password,
                                    newPayPassword:$newPayPassword,
                                    ChknewPayPassword:$ChknewPayPassword,
                                    current:$current
                                )
                            }`,
                            variables: that.reset,
                        });
                        that.reset = {
                            pay_password: "",
                            newPayPassword: "",
                            ChknewPayPassword: "",
                        };
                        Toast.hide();
                        Toast.succeed('更新成功')
                        this.$set(this.isPopupShow, 'align', false)
                    } catch (e) {
                        let msg = getGqlValidFirstErr(e);
                        Toast.hide();
                        Toast.failed(msg ? msg : e.message);
                    }
                } else {
                    try {
                        await that.$apollo.mutate({
                            mutation: gql`mutation forgot(
                            $tel:String!,
                            $smscode:String,
                            $newPayPassword:String,
                            $ChknewPayPassword:String,
                            $current:Int
                            ){
                                forgot(
                                tel:$tel,
                                smscode:$smscode,
                                newPayPassword:$newPayPassword,
                                ChknewPayPassword:$ChknewPayPassword,
                                current:$current
                                )
                                }`,
                            variables: that.reset,
                        });
                        that.reset = {
                            tel: "",
                            smscode: "",
                            newPayPassword: "",
                            ChknewPayPassword: "",
                        };
                        Toast.hide();
                        Toast.succeed('更新成功')
                        this.$set(this.isPopupShow, 'align', false)
                    } catch (e) {
                        let msg = getGqlValidFirstErr(e);
                        Toast.hide();
                        Toast.failed(msg ? msg : e.message);
                    }
                }
            },

            timer() {
                if (this.time > 0) {
                    this.disabled = true;
                    this.time--;
                    this.btntxt = this.time + "秒";
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 0;
                    this.btntxt = this.$t('login.sendsms');
                    this.disabled = false;
                }
            },

            async onRefreshing() {
                let that = this;
                that.detailFilter.id = that.id;
                that.detailFilter.page = 1;
                that.detailFilter.id = that.id,
                    await that.$apollo.queries.detail.refresh();
                that.$refs.detailScrollView.finishRefresh();
            },
            onEndReached() {
                let that = this;
                if (that.detailIsFinished == true) {
                    that.$refs.detailScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.detail.fetchMore({
                    variables: {
                        ...that.detailFilter,
                        id: that.id,
                        page: that.detailFilter.page + 1,
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        detail: previousResult
                    }, {
                        fetchMoreResult: {
                            detail: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.detailScrollView.finishLoadMore();
                        return {
                            detail: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .plane {
        padding: 40px;
        margin-bottom: 20px;
        border-radius: 20px;
        background-color: #006699;

        .qrcode {
            padding: 20px;
            text-align: center;
        }
    }

    .main {
        padding: 0 20px 0 20px;
    }

    .md-field-content {
        padding: 1.5rem 1rem;
        background: #0000005e;
        border-radius: 1rem;
    }

    .profile-box {
        margin-bottom: 20px;
        padding: 1.5rem 1rem 1.5rem 1rem;
        display: flex;
        justify-content: start;
        background-color: #0099ccd1;
        align-items: center;

        .avatar {
            width: 128px;
            height: 128px;
            line-height: 128px;
            text-align: center;
            font-size: 100px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #00000029;
            border: solid 0.15rem #00000026;
            display: block;
            color: #fff;
            text-transform: uppercase;

            img {
                height: 3rem;
                margin-top: 0.7rem;
            }
        }

        .info-list {
            flex: 1;
            padding-left: 30px;
        }

        .edit {
            display: block;
        }

        .md-field {
            padding-top: 13rem !important;
            background: none !important;

            .md-field-content {
                padding: 1.5rem 1rem !important;
                background: #0000005e !important;
                border-radius: 1rem !important;
            }
        }
    }

    .md-landscape-close {
        position: absolute;
        display: block;
        font-size: 1.5625rem;
        line-height: 1.5625rem;
        text-align: center;
        color: #fff;
        top: 0.5rem;
        right: 0.5rem;
    }

    .account {
        color: rgba(255, 255, 0, 0.47);
        background: rgb(7, 122, 165);
        padding: 0.2rem 0.5rem;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.8rem;
    }


    .sendbt {
        color: white;
        width: 25%;
        position: absolute;
        padding: 0 1rem;
        background-color: #2f86f6;
        border-radius: 0.125rem;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
        margin-top: -2.5rem;
        right: 1rem;
    }

    .md-radio-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .md-check-base-box {
        font-size: 0.5rem;
        color: #c5cad5;
    }

    .md-check-base-box.is-checked {
        color: #2f86f6 !important;

    }


    .md-button {
        margin-top: 3rem;
    }

    .md-tab-bar-item {
        width: 33% !important;
    }
</style>


<style scoped>
    >>>.md-tab-bar-item {
        width: 33.33% !important;
        text-align: center;
    }
</style>

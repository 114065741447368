import Vue from 'vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'

import mandMobile from 'mand-mobile'
import 'mand-mobile/lib/mand-mobile.css'
import router from './router'
import i18n from './i18n';
import VueParticles from 'vue-particles'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as echarts from 'echarts'

Vue.use(VueAxios, axios)

Vue.use(VueParticles)
Vue.use(mandMobile)

    Vue.prototype.$echarts = echarts
Vue.prototype.allcoinname = localStorage.getItem('allcoinname')
Vue.prototype.environment = localStorage.getItem('environment')
Vue.prototype.coinname = localStorage.getItem('coinname')
Vue.prototype.appname = localStorage.getItem('appname')

Vue.config.productionTip = false

new Vue({
  apolloProvider: createProvider(),
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

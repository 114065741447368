<template>
    <div class="order-from">
        <md-field class="no_bor" style="background: none !important; padding:0 0 1rem 0!important;">
            <md-input-item type="money" v-model.number="size" :title="$t('report.total')" align="right" is-amount 
                is-formative is-virtual-keyboard @change="onNumberEnter(size)">
                <span slot="right"> T</span>
            </md-input-item>
          
                
            <md-detail-item :title="$t('report.t_cost')" v-if="profile.rank===3">
                <div style="    text-align: right;display: flex;">
                    {{(unitPrice[2]+unitPrice[1]).toFixed(4)}} {{coinname}}
                </div>
            </md-detail-item>
            
            <md-detail-item :title="$t('report.t_cost')" v-else>
                <div style="    text-align: right;display: flex;">
                    {{(Number(unitPrice[0]+unitPrice[1])).toFixed(4)}} {{coinname}}
                </div>
            </md-detail-item>
            
            <md-detail-item :title="$t('report.Purchase_cost')"  v-if="profile.rank===3">
                <div style="    text-align: right;display: flex;">
                    {{((Number(unitPrice[2]+unitPrice[1])*ygsy)).toFixed(4)}} {{coinname}}
                </div>
            </md-detail-item>
            
            <md-detail-item :title="$t('report.Purchase_cost')" v-else>
                <div style="    text-align: right;display: flex;">
                    {{((Number(unitPrice[0]+unitPrice[1])*ygsy)).toFixed(4)}} {{coinname}}
                </div>
            </md-detail-item>
            
            
            <md-detail-item :title="$t('report.produce')">
                <div style="    text-align: right;display: flex;">
                    {{(Number(ygsy*filpertera)).toFixed(4)}} {{coinname}}/24h
                </div>
            </md-detail-item>
            <md-detail-item :title="$t('report.service_charge')">
                20%
            </md-detail-item>
            <md-detail-item :title="$t('report.1D')">
                <div style="    text-align: right;display: flex;">
                    {{Number((ygsy*filpertera*0.8).toFixed(4))}} {{coinname}}/24h
                </div>
            </md-detail-item>
            <md-detail-item >
                <div style="text-align: right;display: flex;">
                    {{Number((ygsy*filpertera*0.8*30).toFixed(4))}} {{coinname}}/30Day
                </div>
            </md-detail-item>
        </md-field>
        <md-button type="default" @click="showConfirmBox">{{$t('recharge.buy')}}</md-button>

        <md-dialog :title="$t('report.insert_pas')" v-model="confirmBox">
              
            <p>{{$t('report.buy_total')}} : {{size}} T</p>
            
            <div v-if="msgVal===0">
               <p >{{$t('report.surplus_sectors_size')}} :0 T</p>
               <p >{{$t('report.Presale')}} : {{size}} T</p>
            </div>
            
            <div v-else-if="size > msgVal">
                <p >{{$t('report.surplus_sectors_size')}} :{{msgVal}} T</p>
                <p >{{$t('report.Presale')}} : {{size-msgVal}} T</p>
            </div>
            
            <div v-else>
                <p >{{$t('report.surplus_sectors_size')}} :{{size}} T</p>
                <p >{{$t('report.Presale')}} : 0 T</p>
            </div>
            
            
            <p v-if="profile.rank===3">{{$t('report.all_price')}} : {{(unitPrice[2]+unitPrice[1])*size}} {{coinname}}</p>
            
            <p v-else>{{$t('report.all_price')}} : {{(unitPrice[0]+unitPrice[1])*size}} {{coinname}}</p>
            
            <md-input-item type="password" v-model="confirmPassword" :brief="$t('report.tips')"
                :placeholder="$t('report.pay_password')" style="color: white;">
            </md-input-item>
            <md-button type="default" @click="createOrder" style="margin-top: 1rem;">{{$t('report.confirm')}}</md-button>
        </md-dialog>
    </div>
</template>
<style lang="scss" scoped>
    .order-from {
        .md-field {
            background: transparent;
        }
        width:100%;
    }
</style>
<style lang="scss">
    .order-from {
        .md-input-item-fake {
            color: #fff;
        }
    }
</style>
<script>
    import {
        Toast
    } from 'mand-mobile'
    import gql from 'graphql-tag'
    import {
        getGqlValidFirstErr
    } from '../utils/common';
    export default {
        data() {
            return {
                profile:null,
                confirmBox: false,
                confirmPassword: '',
                size: 0,
                ygsy: 1,
                filpertera: 0,
                unitPrice:0,
            };
        },
        apollo: {
            profile: {
                query: require('@/graphql/queries/profile.gql'),
            },
            filpertera: {
                query: gql`query filpertera{filpertera}`,
                
            },
            unitPrice: {
                query: gql`query unitPrice{unitPrice}`,
            },
        },
        props: {
            msgVal: Number,
        },
        methods: {
            onNumberEnter(val) {
                console.log(val)
                if (val === null || val === '') {
                    val = 0;
                }
                this.ygsy = val;
            },
            showConfirmBox() {
                let that = this;
                console.log(that.size);
                // if (that.size > that.msgVal) {
                //     Toast.failed(this.$t('report.max'), 3000, true);
                //     return;
                // }

                if (that.size < 1) {
                    Toast.failed(this.$t('report.min'), 3000, true);
                    return;
                }
                that.confirmPassword = '';
                that.confirmBox = true;
            },
            async createOrder() {


                let that = this;
                if (!that.confirmPassword) {
                    Toast.failed(this.$t('report.insert_pas'), 3000, true);
                    return;
                }
                try {
                    await that.$apollo.mutate({
                        mutation: gql`mutation createOrder($input:CreateOrderInput!,$pay_password:String!){createOrder(input:$input,pay_password:$pay_password){id status}}`,
                        variables: {
                            "input": {
                                "type": "FIL",
                                "items": [{
                                    "item_type": "buy_space",
                                    "total": that.size,
                                    "unit": "T"
                                }]
                            },
                            "pay_password": that.confirmPassword
                        },
                    });
                    that.confirmBox = false;
                    that.size = 0;
                    Toast.succeed(this.$t('report.Suc_pur'));
                    let vm = this;
                    vm.$emit('a_even', true)
                } catch (e) {
                    let msg = getGqlValidFirstErr(e);
                    Toast.hide();
                    Toast.failed(msg ? msg : e.message);
                    that.confirmBox = false;
                }
            },
        }
    }
</script>

<template>
    <div class="recharge-page">
        <div class="home" style="height: 91vh;padding-bottom: 0;">
            <nav-bar :title="$t('extension.Promotion_details')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
            <md-swiper ref="swiper" :autoplay="0" :is-prevent="false" :is-loop="false" :has-dots="false" @before-change="onSwiperChange">
                <md-swiper-item style="height: 85vh;">
                    <md-scroll-view ref="allcontributionScrollView" :scrolling-x="false" @end-reached="allcontributionReached" @refreshing="allcontributionRefreshing" v-if="allcontribution">
                        
                        <md-scroll-view-refresh slot="refresh" />
                        <div v-for="allcontribution in allcontribution.data" :key="allcontribution.id" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                          <md-detail-item :title="$t('extension.account')" :content="allcontribution.contributor"/>
                          <md-detail-item :title="$t('public.cre_time')" :content="allcontribution.created_at"/>
                          <md-detail-item :title="$t('report.tag')" :content="allcontribution.title"/>
                          <md-detail-item :title="$t('report.income')" :content="allcontribution.amount+' FIL'"/><!-- 本次分润总额 -->
                        </div>
                        <md-result-page type="empty" class="customized" v-if="allcontribution && allcontribution.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
                        <md-scroll-view-more slot="more" :is-finished="allcontributionFinished" />
                    </md-scroll-view>
                </md-swiper-item>
            </md-swiper>
        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    import {TabBar} from 'mand-mobile'
    export default {
        name: 'Bill',
        components: {
            [TabBar.name]: TabBar,
            NavBar,
        },
        data() {
            return {
                allcontribution: null,
                detailFilter: {
                    page: 1,
                    id:0,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                profile:null,
                allcontributionFilter: {
                    first: 5,
                    page: 1,
                    uid:0,
                    item_type: 'recommend',
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                allcontributionFinished: false,
                detailIsFinished: false,
            };
        },
        apollo: {
            profile: {
                query: gql`query{profile{id account nickname tel parent{id nickname account}}}`,
                update({
                    profile
                }) {
                    return profile;
                },
            },
            allcontribution: {
                 query: gql`query allcontribution($page:Int  $sort:[AllcontributionSortOrderByClause!]){allcontribution:allcontribution(page:$page   sort:$sort){data{id uid contributor title balance_origin balance_to amount user{ id tel nickname} contributor created_at}paginatorInfo{lastPage currentPage perPage }}}`,
                update({
                    allcontribution
                }) {
                    let that = this;
                    that.allcontributionFilter.page = allcontribution.paginatorInfo.currentPage;
                    if (allcontribution.paginatorInfo.lastPage == allcontribution.paginatorInfo.currentPage) {
                        that.allcontributionFinished = true;
                    }
                    return allcontribution;
                },
            },
            
        },
        mounted() {},
        destroyed() {},
        methods: {
            onSwiperChange(from, to) {
                this.current = to
            },
            onTabChange(item, index) {
                this.$refs.swiper.goto(index)
            },
            async allcontributionRefreshing() {
                let that = this;
                that.allcontributionFilter.page = 1;
                await that.$apollo.queries.allcontribution.refresh();
                that.$refs.allcontributionScrollView.finishRefresh();
            },
            
            allcontributionReached() {
                let that = this;
                if (that.allcontributionFinished == true) {
                    that.$refs.allcontributionScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.allcontribution.fetchMore({
                    variables: {
                        ...that.allcontributionFilter,
                        page: that.allcontributionFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        allcontribution: previousResult
                    }, {
                        fetchMoreResult: {
                            allcontribution: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.allcontributionScrollView.finishLoadMore();
                        return {
                            allcontribution: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },

        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
    .md-scroll-view {
        height: 72vh;
    }
    
    .scroll-view-container {
        padding-bottom: 1rem;
    }
    
    .md-tab-bar {
        position: relative;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        background-color: #17172a;
    }
    .md-scroll-view {
        height: 85vh;
    }
    .md-swiper{
         height: 85vh;
    }
</style>

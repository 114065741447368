<template>
    <div class="recharge-page">
        <div class="home">
            <nav-bar :title="$t('profile.detail')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
            <div ref="spacesview" class="balance-box">
                <header class="md-bill-header"
                    style="padding: 0 0 0.5rem 0;    border-bottom: 1px solid #32e8eb; margin-bottom: 0.5rem;">
                    <h3>{{$t('report.all_sectors_size')}}</h3>
                </header>
                <div class="balance">
                    <div class="item">
                        <p>{{$t('report.all_sectors_size')}}</p>
                        <p>
                            {{spaceTotal}} T
                        </p>
                    </div>
                    <div class="item">
                        <p>{{$t('report.buy_sectors_size')}}</p>
                        <p>
                            {{soldTotal}} T
                        </p>
                    </div>
                    <div class="item">
                        <p>{{$t('report.surplus_sectors_size')}}</p>
                        <p>
                            {{surplusspaceTotal}} T
                        </p>
                    </div>
                </div>
            </div>

            <md-tab-bar v-model="current" :items="items" @change="onTabChange"></md-tab-bar>
            <md-swiper ref="swiper" :autoplay="0" :is-prevent="false" :is-loop="false" :has-dots="false"
                @before-change="onSwiperChange">
                <md-swiper-item>
                    <md-scroll-view ref="usersScrollView" :scrolling-x="false" @end-reached="usersReached"
                        @refreshing="usersRefreshing" v-if="users">
                        <md-scroll-view-refresh slot="refresh" />
                        <div v-for="users in users.data" :key="users.id" class="md-water-mark md-bill"
                            style=" padding: 0.5rem 1rem;">
                            <md-detail-item :title="$t('public.account')"
                                :content="users.user.tel.substr(0,3)+'***'+users.user.tel.substr(-3,3)" />
                            <md-detail-item :title="$t('public.sectors_size')" :content="users.totals+' T'" />
                        </div>
                        <md-scroll-view-more slot="more" :is-finished="usersFinished" />
                    </md-scroll-view>
                </md-swiper-item>
                <md-swiper-item>
                    <md-scroll-view ref="orepoolsScrollView" :scrolling-x="false" @end-reached="orepoolsReached"
                        @refreshing="orepoolsRefreshing" v-if="orepools">
                        <md-scroll-view-refresh slot="refresh" />
                        <div class="md-water-mark md-bill"
                            style="display: flex;flex-direction: row;justify-content: space-between;">
                            <div v-for="orepools in orepools.data" :key="orepools.id"
                                style=" padding: 0.5rem 1rem;width: 90%;"
                                @click="seeExa(orepools.address,orepools.names)">
                                <md-detail-item :title="$t('public.Pool_name')" :content="orepools.names" />
                                <md-detail-item :title="$t('public.sectors_size')" :content="orepools.total+' T'" />
                            </div>
                            <div style="align-items: center;justify-items: center;display: flex;">
                                <md-detail-item style="margin-right: 0.2rem;">
                                    <md-icon name="arrow-right"></md-icon>
                                </md-detail-item>
                            </div>
                        </div>

                        <md-scroll-view-more slot="more" :is-finished="orepoolsFinished" />
                    </md-scroll-view>
                </md-swiper-item>

            </md-swiper>

        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    import {
        TabBar
    } from 'mand-mobile'
    export default {
        name: 'Space',
        components: {
            [TabBar.name]: TabBar,
            NavBar,
        },
        data() {
            return {
                current: 1,
                items: [{
                    name: 1,
                    label: this.$t('public.User_list')
                }, {
                    name: 2,
                    label: this.$t('public.Pool_list')
                }],
                orepools: null,
                users: null,
                spaceTotal: 0,
                surplusspaceTotal: 0,
                soldTotal: 0,
                usersFilter: {
                    first: 5,
                    page: 1,
                    sort: [{
                        field: 'TOTAL',
                        order: 'DESC'
                    }]
                },
                orepoolsFilter: {
                    first: 5,
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                orepoolsFinished: false,
                usersFinished: false,
            };
        },
        apollo: {
            users: {
                query: gql`query allSpacesSum($page:Int,$sort:[AllSpacesSumSortOrderByClause!]){users:allSpacesSum(page:$page,expired:false,sort:$sort){data{uid id total   totals deposit start_at expired_at created_at user{id tel nickname}}paginatorInfo{currentPage lastPage count}}}`,


                variables() {
                    return this.usersFilter;
                },
                update({
                    users
                }) {
                    let that = this;
                    that.usersFilter.page = users.paginatorInfo.currentPage;
                    if (users.paginatorInfo.lastPage == users.paginatorInfo.currentPage) {
                        that.usersFinished = true;
                    }
                    return users;
                },
            },



            orepools: {
                query: gql`query orepools($operator_id:ID,$created_at:DateRange,$sort:[OrepoolsSortOrderByClause!],$page:Int){orepools:orepools(operator_id:$operator_id,created_at:$created_at,sort:$sort,page:$page){data{ id names address total operator_id operator{ id tel nickname }  created_at updated_at }paginatorInfo{ total lastPage currentPage perPage }}}`,
                variables() {
                    return this.orepoolsFilter;
                },
                update({
                    orepools
                }) {
                    let that = this;
                    that.orepoolsFilter.page = orepools.paginatorInfo.currentPage;
                    if (orepools.paginatorInfo.lastPage == orepools.paginatorInfo.currentPage) {
                        that.orepoolsFinished = true;
                    }
                    return orepools;
                },
            },



            spaceTotal: {
                query: gql`query spaceTotal{spaceTotal}`,
            },
            surplusspaceTotal: {
                query: gql`query surplusspaceTotal{surplusspaceTotal}`,
            },
            soldTotal: {
                query: gql`query soldTotal{soldTotal}`,
            },


        },


        mounted() {},
        destroyed() {},
        methods: {
            seeExa(url, names) {
                let detail_url = url
                let detail_names = names
                this.$router.push({
                    name: "Iframe",
                    query: {
                        url: detail_url,
                        names: detail_names
                    }
                });
            },
            onSwiperChange(from, to) {
                this.current = to
            },
            onTabChange(item, index) {
                this.$refs.swiper.goto(index)
            },


            async orepoolsRefreshing() {
                let that = this;
                that.orepoolsFilter.page = 1;
                await that.$apollo.queries.orepools.refresh();
                that.$refs.orepoolsScrollView.finishRefresh();
            },

            orepoolsReached() {
                let that = this;
                if (that.orepoolsFinished == true) {
                    that.$refs.orepoolsScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.orepools.fetchMore({
                    variables: {
                        ...that.orepoolsFilter,
                        page: that.orepoolsFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        orepools: previousResult
                    }, {
                        fetchMoreResult: {
                            orepools: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.orepoolsScrollView.finishLoadMore();
                        return {
                            orepools: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },

            async usersRefreshing() {
                let that = this;
                that.usersFilter.page = 1;
                await that.$apollo.queries.users.refresh();
                that.$refs.usersScrollView.finishRefresh();
            },

            usersReached() {
                let that = this;
                if (that.usersFinished == true) {
                    that.$refs.usersScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.users.fetchMore({
                    variables: {
                        ...that.usersFilter,
                        page: that.usersFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        users: previousResult
                    }, {
                        fetchMoreResult: {
                            users: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.usersScrollView.finishLoadMore();
                        return {
                            users: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },


        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 0 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 0.5rem;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }


    .md-scroll-view {
        height: 72vh;
    }

    .scroll-view-container {
        padding-bottom: 1rem;
    }

    .md-tab-bar {
        position: relative;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        background-color: #17172a;
    }
</style>

<template>
    <div class="recharge-page">
        <div class="home" style="height: 91vh;">
            <nav-bar :title="$t('profile.detail')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>
            <md-scroll-view ref="detailScrollView" :scrolling-x="false" @end-reached="onEndReached"
                @refreshing="onRefreshing" v-if="detail">
                <md-scroll-view-refresh slot="refresh" />
                <div v-for="detail in detail.data" :key="detail.id" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                    <header class="md-bill-header" style="padding: 0.5rem 0;">
                        <md-detail-item    class="md-bill-no" >{{detail.created_at}}</md-detail-item >
                    </header>
                    <md-detail-item :title="detail.title" :content="detail.amount+'FIL'" bold /><!-- 本次分润总额 -->
                </div>
                <md-result-page type="empty" class="customized" v-if="detail && detail.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
                <md-scroll-view-more slot="more" :is-finished="detailIsFinished" />
            </md-scroll-view>
            <main-bar current="report" />
        </div>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    export default {
        name: 'Bill',
        components: {
            NavBar,
        },
        created: function()  {
            this.id = this.$route.query.id;
        },
        data() {
            return {
                detail: null,
                detailFilter: {
                    page: 1,
                    id:0,
                    item_type: 'space_income',
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                detailIsFinished: false,
            };
        },
        apollo: {
            detail: {
                query: gql`query userBills($item_type:String $id:String $page:Int $sort:[UserBillsSortOrderByClause!]){detail:userBills(item_type:$item_type id:$id page:$page sort:$sort){data{id title balance_origin balance_to amount created_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    let that = this;
                    that.detailFilter.id=that.$route.query.id;
                    return that.detailFilter;    
                },
                update({
                    detail
                }) {
                    let that = this;
                    that.detailFilter.id= that.id ;
                    that.detailFilter.page = detail.paginatorInfo.currentPage;
                    if (detail.paginatorInfo.lastPage == detail.paginatorInfo.currentPage) {
                        that.detailIsFinished = true;
                    }
                    return detail;
                },
            },
        },
        mounted() {},
        destroyed() {},
        methods: {

            async onRefreshing() {
                let that = this;
                 that.detailFilter.id= that.id ;
                that.detailFilter.page = 1;
                that.detailFilter.id=that.id,
                await that.$apollo.queries.detail.refresh();
                that.$refs.detailScrollView.finishRefresh();
            },
            onEndReached() {
                let that = this;
                if (that.detailIsFinished == true) {
                    that.$refs.detailScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.detail.fetchMore({
                    variables: {
                        ...that.detailFilter,
                        id: that.id ,
                        page: that.detailFilter.page + 1,
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        detail: previousResult
                    }, {
                        fetchMoreResult: {
                            detail: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.detailScrollView.finishLoadMore();
                        return {
                            detail: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }
</style>

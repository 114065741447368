<template>

    <div class="recharge-page">

        <div class="home">
            <nav-bar :title="$t('profile.orderlist')" fixed>
                <template #left>
                    <div @click="$router.back();">
                        <md-icon name="arrow-left" />{{$t('public.return')}}
                    </div>
                </template>
            </nav-bar>

            <md-tab-bar v-model="current" :items="items" style="background-color: #181729;" />
            <div v-if="current===1">
                <md-scroll-view ref="spacesScrollView" :scrolling-x="false" @end-reached="onEndReached"
                    @refreshing="onRefreshing" v-if="spaces">
                    <md-scroll-view-refresh slot="refresh" />
                    <md-field v-for="space in spaces.data" :key="space.id">
                        <md-detail-item :title="$t('report.buy_time')" :content="space.created_at" />
                        <md-detail-item :title="$t('report.finish_time')" :content="space.start_at" />
                        <md-detail-item :title="$t('report.expired_at')" :content="space.expired_at" />
                        <md-detail-item :title="$t('report.deposit')" :content="space.deposit+' '+coinname" />
                        <md-detail-item :title="$t('report.cost')" :content="space.price+' '+coinname" />
                        <md-detail-item :title="$t('report.all_price')" :content="space.all_price+' '+coinname" />
                        <md-detail-item :title="$t('report.total')" :content="space.total+'T'" bold />
                        <md-detail-item :title="$t('report.operation')" v-if="profile.rank ===3">
                            <div @click="showPopUp(space)" class="retrund">
                                {{$t('report.transferable')}}
                            </div>
                        </md-detail-item>
                    </md-field>
                    <md-result-page type="empty" class="customized" v-if="spaces && spaces.paginatorInfo.count==0"
                        :text="$t('public.No_information')"></md-result-page>
                    <md-scroll-view-more slot="more" :is-finished="spacesIsFinished" />

                </md-scroll-view>
            </div>

            <div v-if="current===2">
                <md-scroll-view ref="preSpacesScrollView" :scrolling-x="false" @end-reached="onEndReached2"
                    @refreshing="onRefreshing2" v-if="preSpaces">
                    <md-scroll-view-refresh slot="refresh" />
                    <md-field v-for="preSpaces in preSpaces.data" :key="preSpaces.id">
                        <md-detail-item :title="$t('report.buy_time')" :content="preSpaces.created_at" />
                        <md-detail-item :title="$t('report.wait')" :content="preSpaces.wait+'T'" bold />
                        <md-detail-item :title="$t('report.complete')" :content="preSpaces.complete + 'T'" bold />
                        <md-detail-item :title="$t('report.deposit')"
                            :content="preSpaces.deposit * preSpaces.total+coinname" />
                        <md-detail-item :title="$t('report.price')" :content="preSpaces.price +' '+ coinname" />
                        <md-detail-item :title="$t('report.all_price')" :content="preSpaces.all_price +' '+coinname" />
                        <md-detail-item :title="$t('report.operation')" v-if="preSpaces.uid === profile.id">
                            <div v-if="preSpaces.complete ==0" @click="retrun(preSpaces.id)" class="retrund">
                                {{$t('report.revoke')}}
                            </div>
                            <div v-else>{{$t('report.norevoke')}}</div>
                        </md-detail-item>
                    </md-field>
                    <md-result-page type="empty" class="customized" v-if="preSpaces && preSpaces.paginatorInfo.count==0"
                        :text="$t('public.No_information')">
                    </md-result-page>
                    <md-scroll-view-more slot="more" :is-finished="preSpacesIsFinished" />
                </md-scroll-view>
            </div>
            
            <div v-if="current===3">
                <md-scroll-view ref="transSpacesScrollView" :scrolling-x="false" @end-reached="onEndReached3"
                    @refreshing="onRefreshing3" v-if="transSpaces">
                    <md-scroll-view-refresh slot="refresh" />
                    <md-field v-for="transSpaces in transSpaces.data" :key="transSpaces.id">
                        <md-detail-item :title="$t('report.buy_time')" :content="transSpaces.created_at" />
                        <md-detail-item :title="$t('report.finish_time')" :content="transSpaces.start_at" />
                        <md-detail-item :title="$t('report.expired_at')" :content="transSpaces.expired_at" />
                        <md-detail-item :title="$t('report.deposit')" :content="transSpaces.deposit+' '+coinname" />
                        <md-detail-item :title="$t('report.cost')" :content="transSpaces.price+' '+coinname" />
                        <md-detail-item :title="$t('report.all_price')" :content="transSpaces.all_price+' '+coinname" />
                        <md-detail-item :title="$t('report.total')" :content="transSpaces.total+'T'" bold />
                        <md-detail-item :title="$t('report.Transfertime')" :content="transSpaces.original_at" bold />
                        <md-detail-item :title="$t('report.Receiver')" :content="transSpaces.user.tel" bold />
                    </md-field>
                    <md-result-page type="empty" class="customized" v-if="transSpaces && transSpaces.paginatorInfo.count==0"
                        :text="$t('public.No_information')"></md-result-page>
                    <md-scroll-view-more slot="more" :is-finished="transSpacesIsFinished" />
                </md-scroll-view>
            </div>


            <md-popup v-model="isPopupShow.center">
                <div class="md-example-popup md-example-popup-center" style="margin: 0 10px;">
                    <h3 style="border-bottom:1px solid rgb(16, 150, 194);padding-bottom: 0.3125rem;">{{$t('report.transfer')}}:</h3>
                    <div style="background: #1c3e67; padding: 1rem; margin-top: 1rem;">
                        <md-detail-item :title="$t('report.buy_time')" :content="transferInfo.created_at" />
                        <md-detail-item :title="$t('report.finish_time')" :content="transferInfo.start_at" />
                        <md-detail-item :title="$t('report.expired_at')" :content="transferInfo.expired_at" />
                        <md-detail-item :title="$t('report.deposit')" :content="transferInfo.deposit+' '+coinname" />
                        <md-detail-item :title="$t('report.cost')" :content="transferInfo.price+' '+coinname" />
                        <md-detail-item :title="$t('report.all_price')" :content="transferInfo.all_price+' '+coinname" />
                        <md-detail-item :title="$t('report.total')" :content="transferInfo.total+'T'" bold />
                    </div>
                    <md-input-item v-model="transferAcc" :title="$t('report.TransferTo')"  :placeholder="$t('report.rtumpn')" is-amount></md-input-item>
                    <md-button @click="transfer(transferInfo.id,transferAcc)" style="margin-top: 2rem;">{{$t('report.transfer')}}</md-button>
                </div>
            </md-popup>
            <main-bar current="report" />
        </div>
    </div>
</template>
<script>
    import MainBar from '@/components/MainBar.vue';
    import NavBar from '../../components/Nav';
    import gql from 'graphql-tag';
    import {
        getGqlValidFirstErr
    } from '../../utils/common';
    import {
        Toast
    } from 'mand-mobile'
    import {
        Dialog
    } from 'mand-mobile'
    export default {
        name: 'Home',
        components: {
            NavBar,
            MainBar,
        },
        data() {
            return {
                transferAcc:'',
                transferInfo:[],
                isPopupShow: {},
                current: 1,
                items: [{
                    name: 1,
                    label: this.$t('report.buy_sectors_size'),
                }, {
                    name: 2,
                    label: this.$t('report.Pre_sold'),
                }, {
                    name: 3,
                    label: this.$t('report.Transferred'),
                }],
                spaces: null,
                preSpaces:null,
                transSpaces:null,
                
                spacesFilter: {
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },

                preSpacesFilter: {
                    status: 'normal',
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                
                transSpacesFilter: {
                    status: 'normal',
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                
                spacesIsFinished: false,
                preSpacesIsFinished: false,
                transSpacesIsFinished:false,

                profile: null,
                spaceTotal: 0,
            };
        },
        apollo: {
            profile: {
                query: require('@/graphql/queries/profile.gql'),
            },
            spaces: {
                query: gql`query userSpaces($page:Int,$sort:[UserSpacesSortOrderByClause!]){spaces:userSpaces(page:$page,expired:false,sort:$sort){data{id total deposit price all_price start_at expired_at created_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.spacesFilter;
                },
                update({
                    spaces
                }) {
                    let that = this;
                    that.spacesFilter.page = spaces.paginatorInfo.currentPage;
                    if (spaces.paginatorInfo.lastPage == spaces.paginatorInfo.currentPage) {
                        that.spacesIsFinished = true;
                    }
                    return spaces;
                },
            },

            preSpaces: {
                query: gql`query userpreSpaces($page:Int,$status:String, $sort:[UserpreSpacesSortOrderByClause!]){
                            preSpaces:userpreSpaces(
                            page:$page,
                            status:$status
                            expired:false,
                            sort:$sort){ data{ id sn uid deposit price all_price total wait complete status user{id tel nickname}  over_at created_at updated_at  }
                                paginatorInfo{ currentPage  lastPage  count
                                }
                            }
                        }`,
                variables() {
                    return this.preSpacesFilter;
                },
                update({
                    preSpaces
                }) {
                    let that = this;
                    that.preSpacesFilter.page = preSpaces.paginatorInfo.currentPage;
                    if (preSpaces.paginatorInfo.lastPage == preSpaces.paginatorInfo.currentPage) {
                        that.preSpacesIsFinished = true;
                    }
                    return preSpaces;
                },
            },
            
            transSpaces: {
                query: gql`query userTransSpaces($page:Int,$sort:[UserTransSpacesSortOrderByClause!]){transSpaces:userTransSpaces(page:$page,expired:false,sort:$sort){data{id total deposit price all_price start_at expired_at user{id tel nickname}  created_at original_uid original_at}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.transSpacesFilter;
                },
                update({
                    transSpaces
                }) {
                    let that = this;
                    that.transSpacesFilter.page = transSpaces.paginatorInfo.currentPage;
                    if (transSpaces.paginatorInfo.lastPage == transSpaces.paginatorInfo.currentPage) {
                        that.transSpacesIsFinished = true;
                    }
                    return transSpaces;
                },
            },


            spaceTotal: {
                query: gql`query spaceTotal{spaceTotal}`,
            },
        },
        mounted() {},
        destroyed() {},
        methods: {
            showPopUp(info) {
                 this.$set(this.isPopupShow,'center', true)
                 this.transferInfo=info
            },
            transfer(id,uid){
                this.axios.post('/api/transfer', {
                    id: id,
                    uid:uid
                }).then((res) => {
                    if(res.data.err ===1){
                        Toast.failed(res.data.msg);
                    }else{
                        Toast.succeed(res.data.msg);
                        this.$set(this.isPopupShow, 'center', false)
                        this.onRefreshing()
                    }
                })
                
            },
            retrun(ids) {
                let that = this;
                Dialog.confirm({
                    title: '',
                    content: that.$t('report.sfcx'),
                    confirmText: that.$t('common.ok'),
                    cancelText: that.$t('common.cancel'),
                    onConfirm() {
                        try {
                            that.$apollo.mutate({
                                mutation: gql`mutation retrunPerOrder($id:ID){retrunPerOrder(id:$id){id status}}`,
                                variables: {
                                    "id": ids
                                },
                            });
                            that.$apollo.queries.preSpaces.refresh();

                            Toast.succeed(that.$t('report.Suc_revoke'));
                        } catch (e) {
                            let msg = getGqlValidFirstErr(e);
                            Toast.failed(msg ? msg : e.message);
                        }
                    }
                })
            },
            async onRefreshing() {
                let that = this;
                that.spacesFilter.page = 1;
                await that.$apollo.queries.spaces.refresh();
                that.$refs.spacesScrollView.finishRefresh();
            },

            async onRefreshing2() {
                let that = this;
                that.preSpacesFilter.page = 1;
                await that.$apollo.queries.preSpaces.refresh();
                that.$refs.preSpacesScrollView.finishRefresh();
            },
            
            async onRefreshing3() {
                let that = this;
                that.transSpacesFilter.page = 1;
                await that.$apollo.queries.transSpaces.refresh();
                that.$refs.transSpacesScrollView.finishRefresh();
            },


            onEndReached() {
                let that = this;
                if (that.spacesIsFinished == true) {
                    that.$refs.spacesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.spaces.fetchMore({
                    variables: {
                        ...that.spacesFilter,
                        page: that.spacesFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        spaces: previousResult
                    }, {
                        fetchMoreResult: {
                            spaces: fetchMoreResult
                        }
                    }) {
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.spacesScrollView.finishLoadMore();
                        return {
                            spaces: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },


            onEndReached2() {
                let that = this;
                if (that.preSpacesIsFinished == true) {
                    that.$refs.preSpacesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.preSpaces.fetchMore({
                    variables: {
                        ...that.preSpacesFilter,
                        page: that.preSpacesFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        preSpaces: previousResult
                    }, {
                        fetchMoreResult: {
                            preSpaces: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.preSpacesScrollView.finishLoadMore();
                        return {
                            preSpaces: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
            
            onEndReached3() {
                let that = this;
                if (that.transSpacesIsFinished == true) {
                    that.$refs.transSpacesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.transSpaces.fetchMore({
                    variables: {
                        ...that.transSpacesFilter,
                        page: that.transSpacesFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        transSpaces: previousResult
                    }, {
                        fetchMoreResult: {
                            transSpaces: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.transSpacesScrollView.finishLoadMore();
                        return {
                            transSpaces: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
            
        },
    }
</script>
<style lang="scss" scoped>
    .md-scroll-view {
        height: 80vh;
    }
    .home {
        padding: 20px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;

            .item {
                text-align: center;
            }
        }
    }

    .chat {
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .recharge-page {
        color: #fff;
        padding-top: 100px;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }

    // .md-field {
    //     border-bottom: 1px solid #616161;
    //     border-radius: 0;
    // }

    .zrbtn {
        background: #1c3e67;
        color: white;
        padding: 5px 20px;
        width: 20%;
        border-radius: 4px;
        float: right;
        margin-top: 5px;
    }


    .retrund {
        color: rgb(255 255 255);
        text-decoration: none;
        background: #1096c2;
        padding: 5px 20px;
        border-radius: 5px;
    }

    .md-example-popup-center {
        background: #171729eb;
        padding: 2.5rem;
        border-radius: 10px;
    }
    
   
</style>
<style>
    .md-field-item-title {
        max-width: 3rem !important;
    }
</style>
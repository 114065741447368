<template>
    <div class="recharge-page">
        <nav-bar :title="$t('extension.Promotion_details')" fixed>
            <template #left>
                <div @click="$router.back();">
                    <md-icon name="arrow-left" />{{$t('public.return')}}
                </div>
            </template>
            <template #right>
                <div  @click="$router.push('/Extensioninfo')">
                    {{$t('extension.details')}}
                </div>
            </template>
        </nav-bar>
     
      <div class="balance-box">
       
          <div class="balance">
            <div class="qrcode">
                <img :src="qrcodeUrl" style="border-radius: 0.5rem;width: 100%;" />
                
            </div>
           <div style="display: flex;flex-direction: column;margin-left: 1rem;">
              <!-- <div class="item" style="display: flex;flex-direction: row;align-items: center;" v-if="profile.nickname">
                   <p>{{$t('login.filaccount')}}:</p>
                   <p style="margin-left: 1rem;">{{profile.nickname}}</p>
               </div> -->
               <div class="item" style="display: flex;flex-direction: row;align-items: center;">
                   <p>{{$t('login.code')}}:</p>
                   <p style="margin-left: 1rem;">{{profile.account}}</p>
               </div>
               <md-button type="default" @click="copyUrl()" >{{$t('extension.url')}}</md-button>
           </div>
          </div>
      </div>
       <div class="balance-box">
         
            <div class="balance" style="justify-content: space-between;">
               <div class="item">
                   <p>{{$t('extension.Promotion_income')}}</p>
                   <p>{{extensiondetails[0]}} {{coinname}}</p>
               </div>
               <div class="item">
                   <p>{{$t('extension.Promotion_members')}}</p>
                   <p>{{extensiondetails[1]?extensiondetails[1]:0}}</p>
               </div>
               <div class="item">
                   <p>{{$t('extension.Total_number')}}</p>
                   <p>{{extensiondetails[2]?extensiondetails[2]:0}}</p>
               </div> 
             
            </div>
        </div>


        <md-scroll-view ref="detailScrollView" :scrolling-x="false" @end-reached="onEndReached" @refreshing="onRefreshing" v-if="detail" >
            <md-scroll-view-refresh slot="refresh" />
            <div v-for="detail in detail.data" :key="detail.id" @click="seeExa(detail.id)" class="md-water-mark md-bill" style=" padding: 0.5rem 1rem;">
                <header class="md-bill-header" style="padding: 0.5rem 0;">
                    <div class="md-bill-no">{{$t('extension.created_at')}}:{{detail.created_at}}</div>
                    <div class="md-bill-no" style="background:rgb(44 43 82); padding: 0.2rem 0.5rem; border-radius: 0.5rem;">{{$t('public.details')}}</div>
                </header>
                <div class="md-bill-neck"><span></span></div>
                <md-detail-item :title="$t('extension.account')" :content="detail.tel" />
                <md-detail-item :title="$t('report.sectors_size')" :content="detail.speces_sum?detail.speces_sum:0+' T'" />
                <md-detail-item :title="$t('extension.Promotion_members')" :content="detail.children_count" />
                <md-detail-item :title="$t('extension.Creating_revenue')" :content="detail.creating_revenue?detail.creating_revenue:0+' FIL'" />
            </div>
            <md-result-page type="empty" class="customized" v-if="detail && detail.paginatorInfo.count==0"    :text="$t('public.No_information')"></md-result-page>
            <md-scroll-view-more slot="more" :is-finished="detailIsFinished" />
        </md-scroll-view>
    </div>
</template>
<script>
    import NavBar from '../../components/Nav';
    import QRCode from 'qrcode'
    import gql from 'graphql-tag';
    import {Toast} from 'mand-mobile'
    export default {
        name: 'Detail',
        components: {
            NavBar,
        },
        data() {
            return {
                 baseUrl: window.location.protocol + '//' + window.location.host + '/m/#/',
                detail: null,
                 qrcodeUrl: null,
                 url:null,
                extensiondetails: {
                    profit: 0,
                    promoters: 0,
                    team: 0
                },
                detailFilter: {
                    first:5,
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                detailIsFinished: false,
            };
        },
        apollo: {
            profile: {
                query: gql`query{profile{id account  tel parent{id  account}}}`,
                update({
                    profile
                }) {
                    let that = this;
                    that.url= that.baseUrl + '?source_user=' + profile.id
                    QRCode.toDataURL(that.baseUrl + '?source_user=' + profile.id, {
                            errorCorrectionLevel: 'H'
                        })
                        .then(url => {
                            that.qrcodeUrl = url;
                        });
                    return profile;
                },
            },
           detail: {
               query: gql`query userReferrals($page:Int $sort:[UserReferralsSortOrderByClause!]){detail:userReferrals(page:$page sort:$sort){data{ created_at id children_count creating_revenue descendants_count tel  speces_sum parent{id  created_at}} paginatorInfo{currentPage lastPage count}}}`,
               variables() {
                   let that = this;
                   that.detailFilter.id = that.$route.query.id;
                   return that.detailFilter;
               },
               update({
                   detail
               }) {
                   let that = this;
                   that.detailFilter.id = that.id;
                   that.detailFilter.page = detail.paginatorInfo.currentPage;
                   if (detail.paginatorInfo.lastPage == detail.paginatorInfo.currentPage) {
                       that.detailIsFinished = true;
                   }
                   return detail;
               },
           },
            extensiondetails: {
                query: gql`query extensiondetails{extensiondetails}`,
            },
        },


        mounted() {},
        destroyed() {},
        methods: {
            copyUrl() {
                let that = this;
                console.log(that.baseUrl + '?source_user=' + that.profile.id);
                that.url= that.baseUrl + '?source_user=' + that.profile.id;
                var domUrl = document.createElement("input");
                domUrl.value = that.url;
                domUrl.id = "creatDom";
                document.body.appendChild(domUrl);
                domUrl.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                let creatDom = document.getElementById("creatDom");
                creatDom.parentNode.removeChild(creatDom);
                Toast.hide();
                Toast.succeed(this.$t('public.copy_don'));
            },
            async onRefreshing() {
                let that = this;
                that.detailFilter.page = 1;
                await that.$apollo.queries.detail.refresh();
                that.$refs.detailScrollView.finishRefresh();
            },
            seeExa(id) {
                  let detail_id = id  //把id重新赋值
                     //这里就是跳转name: "Bill"是指跳转到Bill页面上面
                     //query: { id: detail_id}就是我们跳转过去需要带的参数
                     this.$router.push({ name: "Extensionchi", query: { id: detail_id} });  
            },
            onEndReached() {
                let that = this;
                if (that.detailIsFinished == true) {
                    that.$refs.detailScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.detail.fetchMore({
                    variables: {
                        ...that.detailFilter,
                        page: that.detailFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        detail: previousResult
                    }, {
                        fetchMoreResult: {
                            detail: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.detailScrollView.finishLoadMore();
                        return {
                            detail: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .recharge-page {
        color: #fff;
        z-index: 999;
        position: relative;
         padding: 3.5rem 0.5rem 0 0.5rem;

        .c-nav {
            color: #fff;
            background-color: rgb(28 62 103);
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 0.5rem;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            .item {
                text-align: center;

                >* {
                    vertical-align: middle;
                }
            }
        }
    }

    .qrCode {
        margin: 1rem auto;
        display: block;
        width: 9.4rem;
        height: 9.4rem;
        padding: 1.5rem;
        background: white;
        border-radius: 1rem;

    }
    .md-scroll-view {
        height: 62vh;
    }
        .qrcode {
            width: 40%;
            text-align: center;
        }
    .inner-popup {
        .md-popup-box {
            height: 70%;
        }
    }
    p{
        font-size: 0.8rem;
    }
</style>
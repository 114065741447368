<template>
    <div class="home">
        <nav-bar :title="$t('common.Report')" fixed>
            <!-- <template #left v-if="isAndroid">
                <a href="http://47.242.233.90:8888/down/l8VLEGrk7ck4"
                    style=" background: rgb(9 144 184);padding: 0.5rem 1rem;border-radius: 0.5rem;">{{$t('public.Download')}}</a>
            </template> -->
            <template #right>
                <div @click="isLangShow=true">
                    {{$i18n.locale.toUpperCase()}}
                </div>
            </template>
        </nav-bar>

        <md-selector v-model="isLangShow" default-value="2"
            :data="[{value: 'zh',text: '中文',},{value: 'en',text: 'english',}]" max-height="320px"
            :title="$t('common.SelectLang')" large-radius @choose="onSelectorChoose">
        </md-selector>


        <div ref="spacesview" class="balance-box">

            <header class="md-bill-header"
                style="padding: 0 0 0.5rem 0; border-bottom: 1px solid #1096c2; margin-bottom: 0.5rem;">
                <h3>{{$t('report.all_sectors_size')}}</h3>
                <div class="md-bill-no" @click="$router.push('/space')"
                    style="background: grey;padding: 0.2rem 0.5rem; border-radius: 0.3rem;color: #ffffff;}">
                    {{$t('public.details')}}
                </div>
            </header>
            <div class="balance">
                <div class="item">
                    <p>{{$t('report.all_sectors_size')}}</p>
                    <p>
                        {{spaceTotal}} T
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.buy_sectors_size')}}</p>
                    <p v-if="profile">
                        {{soldTotal}} T
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.surplus_sectors_size')}}</p>
                    <p>
                        {{surplusspaceTotal}} T
                    </p>
                </div>
                <div class="item">
                    <p>{{$t('report.Pre_sold')}}</p>
                    <p>
                        {{presoldTotal}} T
                    </p>
                </div>
            </div>
        </div>
        <div class="balance-box">
            <header class="md-bill-header"
                style="padding: 0 0 0.5rem 0; border-bottom: 1px solid #1096c2; margin-bottom: 0.5rem;">
                <h3>{{$t('report.Blockreward')}}</h3>
                <div class="md-bill-no mbn">{{$t('report.ThirtyDaysReward')}}：{{thirtydays.toFixed(4)}}{{coinname}}</div>
            </header>
            <div id="myChart" style="width: auto;height: 200px;"></div>
        </div>


        <div class="balance-box" style="position: relative;">

            <header class="md-bill-header"  style="padding: 0 0 0.5rem 0;    border-bottom: 1px solid #1096c2; margin-bottom: 0.5rem;">
                <h3> {{$t('recharge.buy')}}</h3>
                <div>
                    <div class="md-bill-no mbn" style="margin-bottom: 2px;" v-if="profile.rank===3">
                        {{$t('report.cost')}}：{{(unitPrice[2]).toFixed(4)}} {{coinname}}/T
                    </div>
                    <div class="md-bill-no mbn" style="margin-bottom: 2px;" v-else>
                        {{$t('report.cost')}}：{{(unitPrice[0]).toFixed(4)}} {{coinname}}/T
                    </div>
                    <div class="md-bill-no mbn">
                        {{$t('report.deposit')}}：{{(unitPrice[1]).toFixed(4)}} {{coinname}}/T
                    </div>
                </div>
            </header>

            <div class="balance" style=" margin-bottom: 10px;">
                <order @a_even="a_even" :msg-val="surplusspaceTotal" />
            </div>

            <div style="display: flex;justify-content: space-between;">
                <div class="per_l"><span>{{$t('report.fil_balance')}}：</span>
                    {{(profile.fil_balance?profile.fil_balance:0).toFixed(4)}} {{coinname}}
                </div>
                <div @click="per()" class="per"><span>!</span>{{$t('report.Salesdescription')}}</div>
            </div>
        </div>



        <md-tab-bar v-model="current" :items="items" style="background-color: #181729;" />
        <md-field>
            <div v-if="current===1">
                <md-scroll-view ref="spacesScrollView" :scrolling-x="false" @end-reached="onEndReached"
                    @refreshing="onRefreshing" v-if="spaces">
                    <md-scroll-view-refresh slot="refresh" />
                    <md-field v-for="space in spaces.data" :key="space.id">
                        <md-detail-item v-if="space.uid === profile.id" :title="$t('report.account')"
                            :content="space.user.tel" />
                        <md-detail-item v-else :title="$t('report.account')"
                            :content="space.user.tel.substr(0,3)+'******'+space.user.tel.substr(-3,3)" />
                        <md-detail-item :title="$t('report.buy_time')" :content="space.created_at" />
                        <md-detail-item :title="$t('report.expired_at')" :content="space.expired_at" />
                        <md-detail-item :title="$t('report.total')" :content="space.total+'T'" bold />
                    </md-field>
                    <md-scroll-view-more slot="more" :is-finished="spacesIsFinished" />
                </md-scroll-view>
            </div>

            <div v-if="current===2">
                <md-scroll-view ref="preSpacesScrollView" :scrolling-x="false" @end-reached="onEndReached2"
                    @refreshing="onRefreshing2" v-if="preSpaces">
                    <md-scroll-view-refresh slot="refresh" />
                    <md-field v-for="preSpaces in preSpaces.data" :key="preSpaces.id">
                        <md-detail-item v-if="preSpaces.uid === profile.id" :title="$t('report.account')"
                            :content="preSpaces.user.tel" />
                        <md-detail-item v-else :title="$t('report.account')"
                            :content="preSpaces.user.tel.substr(0,3)+'******'+preSpaces.user.tel.substr(-3,3)" />
                        <md-detail-item :title="$t('report.buy_time')" :content="preSpaces.created_at" />

                        <md-detail-item :title="$t('report.wait')" :content="preSpaces.wait+'T'" bold />
                        <md-detail-item :title="$t('report.complete')" :content="preSpaces.complete+'T'" bold />

                        <md-detail-item :title="$t('report.operation')" v-if="preSpaces.uid === profile.id">
                            <div v-if="preSpaces.complete ==0" @click="retrun(preSpaces.id)" class="retrund">
                                {{$t('report.revoke')}}
                            </div>
                            <div v-else>{{$t('report.norevoke')}}</div>
                        </md-detail-item>
                    </md-field>
                    <md-scroll-view-more slot="more" :is-finished="preSpacesIsFinished" />
                </md-scroll-view>
            </div>
        </md-field>
        <main-bar current="report" />
    </div>
</template>

<script>
    import MainBar from '@/components/MainBar.vue'
    import Order from '@/components/Order.vue'
    import gql from 'graphql-tag'
    import NavBar from '@/components/Nav'

    import {
        getGqlValidFirstErr
    } from '../../utils/common';

    import {
        Toast
    } from 'mand-mobile'
    import {
        Dialog
    } from 'mand-mobile'
    import {
        setLang
    } from '@/i18n'
    export default {
        name: 'Home',
        components: {
            NavBar,
            MainBar,
            Order,
        },
        data() {
            return {
                current: 1,
                isAndroid: null,
                spaces: null,
                preSpaces: null,
                spacesFilter: {
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },

                preSpacesFilter: {
                    status: 'normal',
                    page: 1,
                    sort: [{
                        field: 'CREATED_AT',
                        order: 'DESC'
                    }]
                },
                thirtydays: null,

                spacesIsFinished: false,
                preSpacesIsFinished: false,

                profile: null,
                spaceTotal: 0,
                surplusspaceTotal: 0,
                soldTotal: 0,
                presoldTotal: 0,
                isLangShow: false,
                unitPrice: 0,
                items: [{
                    name: 1,
                    label: this.$t('report.buy_sectors_size'),
                }, {
                    name: 2,
                    label: this.$t('report.Pre_sold'),
                }],
            };
        },
        apollo: {
            profile: {
                query: require('@/graphql/queries/profile.gql'),
            },
            unitPrice: {
                query: gql`query unitPrice{unitPrice}`,
            },
            spaces: {
                query: gql`query allSpaces($page:Int,$sort:[AllSpacesSortOrderByClause!]){spaces:allSpaces(page:$page,expired:false,sort:$sort){data{uid id total deposit start_at expired_at created_at user{id tel nickname}}paginatorInfo{currentPage lastPage count}}}`,
                variables() {
                    return this.spacesFilter;
                },
                update({
                    spaces
                }) {
                    let that = this;
                    that.spacesFilter.page = spaces.paginatorInfo.currentPage;
                    if (spaces.paginatorInfo.lastPage == spaces.paginatorInfo.currentPage) {
                        that.spacesIsFinished = true;
                    }
                    return spaces;
                },
            },

            preSpaces: {
                query: gql`query preSpaces($page:Int,$status:String, $sort:[PreSpacesSortOrderByClause!]){
                            preSpaces:preSpaces(
                            page:$page,
                            status:$status
                            expired:false,
                            sort:$sort){
                                data{
                                    id
                                    sn
                                    uid
                                    deposit
                                    price
                                    all_price
                                    total
                                    wait
                                    complete
                                    status
                                    user{id tel nickname}
                                    over_at
                                    created_at
                                    updated_at
                                }
                                paginatorInfo{
                                    currentPage 
                                    lastPage 
                                    count
                                }
                            }
                        }`,
                variables() {
                    return this.preSpacesFilter;
                },
                update({
                    preSpaces
                }) {
                    let that = this;
                    that.preSpacesFilter.page = preSpaces.paginatorInfo.currentPage;
                    if (preSpaces.paginatorInfo.lastPage == preSpaces.paginatorInfo.currentPage) {
                        that.preSpacesIsFinished = true;
                    }
                    return preSpaces;
                },
            },
            spaceTotal: {
                query: gql`query spaceTotal{spaceTotal}`,
            },
            surplusspaceTotal: {
                query: gql`query surplusspaceTotal{surplusspaceTotal}`,
            },
            soldTotal: {
                query: gql`query soldTotal{soldTotal}`,
            },

            presoldTotal: {
                query: gql`query presoldTotal{presoldTotal}`,
            },


        },
        mounted() {
            let that = this;
            var u = navigator.userAgent;
            that.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            setTimeout(() => {
                this.drawLine();
            }, 100)
        },
        onShow() {},
        destroyed() {},
        methods: {
            drawLine() {
                this.axios.post('/api/getTiker', {
                    time: '30'
                }).then((res) => {
                    this.thirtydays = res.data.sum
                    let arr = []
                    for (let i in res.data.tikers) {
                        arr.push(res.data.tikers[i])
                    }
                    const xname = []
                    const value = []

                    arr.reverse().forEach(function(item) {
                        xname.push(item.crea_at)
                        value.push(item.sum_mined_reward)
                    })



                    setTimeout(() => {
                        let myChart = this.$echarts.init(document.getElementById('myChart'))


                        myChart.setOption({
                            title: {
                                text: '',
                            },
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
                            },
                            grid: {
                                left: '0',
                                right: '0',
                                top: '5%',
                                bottom: '0',
                                containLabel: true
                            },
                            xAxis: {
                                data: xname,
                                axisLabel: {
                                    interval: 1,
                                    rotate: 50 //角度顺时针计算的
                                },
                                 splitLine:{show: false}
                            },
                            yAxis: {
                                type: 'value',
                                  splitLine:{show: false}
                            },
                            series: [{
                                name: '奖励',
                                type: 'line',
                                data: value,
                                symbol: "circle",
                                // smooth: true,
                                legendHoverLink: false,
                                showSymbol: false,
                                lineStyle: {
                                    color: "#ffffff",
                                    width: 1
                                },
                                hoverAnimation:false,
                                silent: true,
                                // itemStyle:{
                                //      color:"#ffffff"
                                // },
                                areaStyle: {
                                    color: "rgba(255, 255, 255, 0.2)"
                                }
                            }]
                        });

                        let chats = []
                        chats.push(myChart)
                        window.addEventListener("resize", function() {
                            for (var i = 0; i < chats.length; i++) {
                                chats[i].resize()
                            }
                        })


                    }, 100)
                })




            },

            // getTiker() {
            //     this.axios.post('/api/getTiker', {
            //         time: '30'
            //     }).then((response) => {
            //         console.log(response)
            //     })
            // },


            per() {
                Dialog.alert({
                    title: this.$t('report.Salesdescription'),
                    content: this.$t('report.description'),
                    confirmText: this.$t('common.ok'),
                })

            },
            retrun(ids) {
                let that = this;
                Dialog.confirm({
                    title: '',
                    content: that.$t('report.sfcx'),
                    confirmText: that.$t('common.ok'),
                    cancelText: that.$t('common.cancel'),
                    onConfirm() {
                        try {
                            that.$apollo.mutate({
                                mutation: gql`mutation retrunPerOrder($id:ID){retrunPerOrder(id:$id){id status}}`,
                                variables: {
                                    "id": ids
                                },
                            });
                            that.$apollo.queries.preSpaces.refresh();

                            Toast.succeed(that.$t('report.Suc_revoke'));
                        } catch (e) {
                            let msg = getGqlValidFirstErr(e);
                            Toast.failed(msg ? msg : e.message);
                        }
                    }
                })
            },
            async a_even(e) {
                if (e == true) {
                    let that = this;
                    await that.$apollo.queries.spaces.refresh();
                    await that.$apollo.queries.preSpaces.refresh();

                    await that.$apollo.queries.spaceTotal.refresh();
                    await that.$apollo.queries.surplusspaceTotal.refresh();
                    await that.$apollo.queries.soldTotal.refresh();
                    await that.$apollo.queries.presoldTotal.refresh();

                    that.$refs.spacesScrollView.finishRefresh();
                    that.$refs.preSpacesScrollView.finishRefresh();
                } //e的值，就为子组件内，a_even事件的事件参数
            },
            onSelectorChoose({
                value
            }) {
                setLang(value);
                this.items[0].label = this.$t('report.buy_sectors_size');
                this.items[1].label = this.$t('report.Pre_sold');
            },
            async onRefreshing() {
                let that = this;
                that.spacesFilter.page = 1;
                await that.$apollo.queries.spaces.refresh();
                that.$refs.spacesScrollView.finishRefresh();
            },

            async onRefreshing2() {
                let that = this;
                that.preSpacesFilter.page = 1;
                await that.$apollo.queries.preSpaces.refresh();
                that.$refs.preSpacesScrollView.finishRefresh();
            },


            onEndReached() {
                let that = this;
                if (that.spacesIsFinished == true) {
                    that.$refs.spacesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.spaces.fetchMore({
                    variables: {
                        ...that.spacesFilter,
                        page: that.spacesFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        spaces: previousResult
                    }, {
                        fetchMoreResult: {
                            spaces: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.spacesScrollView.finishLoadMore();
                        return {
                            spaces: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },

            onEndReached2() {
                let that = this;
                if (that.preSpacesIsFinished == true) {
                    that.$refs.preSpacesScrollView.finishLoadMore();
                    return;
                }
                that.$apollo.queries.preSpaces.fetchMore({
                    variables: {
                        ...that.preSpacesFilter,
                        page: that.preSpacesFilter.page + 1
                    },
                    // 用新数据转换之前的结果
                    updateQuery({
                        preSpaces: previousResult
                    }, {
                        fetchMoreResult: {
                            preSpaces: fetchMoreResult
                        }
                    }) {
                        console.log(previousResult, fetchMoreResult);
                        let data = [...previousResult.data, ...fetchMoreResult.data];
                        that.$refs.preSpacesScrollView.finishLoadMore();
                        return {
                            preSpaces: {
                                __typename: previousResult.__typename,
                                paginatorInfo: fetchMoreResult.paginatorInfo,
                                data,
                            }
                        };
                    },
                });
            },


        },
    }
</script>
<style lang="scss" scoped>
    .home {
        padding: 110px 20px 120px 20px;

        .img-icon {
            width: 48px;
            height: 48px;
            vertical-align: middle;
        }
    }

    .balance-box {
        padding: 40px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #2E2D55;

        .balance {
            display: flex;
            justify-content: space-between;


            .item {
                text-align: center;
            }
        }
    }

    .chat {
        background-color: #fff;
        padding: 10px;
        overflow-x: scroll;

        .chat-inner {
            width: 100%;
            height: 18rem;
        }
    }

    .md-field {
        // border-bottom: 1px dotted #32e8eb !important;
    }

    .md-scroll-view {
        height: 30vh;
    }

    .per {
        font-size: 0.875rem;
        height: 20px;
        padding: 10px 10px;
        text-align: center;
        line-height: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 5px;

        span {
            background: #FF0000;
            color: #fbff00;
            display: block;
            font-weight: bold;
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 100%;
            margin-right: 10px;
        }
    }

    .per_l {
        font-size: 0.875rem;
        height: 20px;
        padding: 10px 10px;
        text-align: center;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 5px;
    }


    .retrund {
        color: rgb(255 255 255);
        text-decoration: none;
        background: #1096c2;
        padding: 5px 20px;
        border-radius: 5px;
    }

    .mbn {
        background: #1096c2;
        padding: 0.1rem 0.5rem;
        border-radius: 0.3rem;
        color: #ffffff;
    }
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const support=['en','zh'];
const defaultLang=window.navigator.language.slice(0, 2);

let lang=localStorage.getItem('lang');
if(!lang){
  lang=defaultLang;
}
if(support.indexOf(lang)<0){
  lang=support[0];
}

const i18n = new VueI18n({
  // 使用localStorage存储语言状态是为了保证页面刷新之后还是保持原来选择的语言状态
  locale: lang , // 定义默认语言为英文 
  messages: {
    'zh': require('@/assets/languages/zh.json'),
    'en': require('@/assets/languages/en.json')
  }
})

export function setLang(lang){
  localStorage.setItem('lang',lang);
  i18n.locale=lang;
}

export default i18n
